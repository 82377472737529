import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button as AntdButton } from './antd';

export const Button = styled(AntdButton)`
  ${({ theme }) => {
    return css`
      font-family: ${theme.fontFamily}, sans-serif;
      height: auto;
      &.ant-btn-primary {
        font-size: ${theme.fontSizeButton};
        background-color: ${theme.primaryGreen};
        border: 1px solid ${theme.primaryGreen};
        color: ${theme.gray2};
        line-height: 23px;
        font-weight: 600;
        padding: ${theme.paddingButton};
        &:hover {
          background-color: ${theme.primaryColorHover};
          border: 1px solid ${theme.primaryColorHover};
        }
        &:focus {
          outline: 3px solid ${theme.borderFocus};
        }
        &:active {
          background-color: ${theme.colorActiveButton};
          border: 1px solid ${theme.colorActiveButton};
        }
        &[disabled] {
          background-color: ${theme.disabled};
          border: 1px solid ${theme.disabled};
          color: ${theme.textDisabled};
          svg {
            path {
              fill: ${theme.textDisabled};
            }
          }
        }
      }
      &.ant-btn-primary:disabled::before {
        background: #fff;
        bottom: -1px;
        content: '';
        display: block;
        left: -1px;
        opacity: 0.35;
        pointer-events: none;
        position: absolute;
        right: -1px;
        top: -1px;
        transition: opacity 0.2s;
        z-index: 1;
      }
      &.ant-btn-link {
        font-size: ${theme.fontSizeLabel};
        color: ${theme.linkColor};
        line-height: 21px;
        font-weight: 600;
        padding: 0;
        &:hover {
          background-color: transparent;
          span {
            text-decoration: underline;
          }
        }
      }
      &.ant-btn-default {
        &.ant-btn-variant-outlined{
          &:not(disabled){
            font-size: ${theme.fontSizeLabel};
            color: ${theme.linkColor};
            line-height: 21px;
            font-weight: 400;
            padding: ${theme.paddingButton};
            border-radius: ${theme.borderRadius};
            border: 1px solid ${theme.primaryGreen};
            &:hover {
              background-color: ${theme.primaryColorHover};
              border: 1px solid ${theme.primaryColorHover};
              color: ${theme.whiteBackground};
              svg {
                fill: ${theme.whiteBackground};
                path {
                  fill: ${theme.whiteBackground};
                }
              }
            }
            &:active {
              background-color: ${theme.primaryColorHover};
              border: 1px solid ${theme.primaryColorHover};
              color: ${theme.whiteBackground};
              svg {
                fill: ${theme.whiteBackground};
                path {
                  fill: ${theme.whiteBackground};
                }
              }
            }
            &:focus {
              outline: 3px solid ${theme.borderFocus};
            }
          }
        }
        &[disabled] {
          background-color: ${theme.disabled};
          border: 1px solid ${theme.disabled};
          color: ${theme.textDisabled};
          svg {
            path {
              fill: ${theme.textDisabled};
            }
          }
        }
      }
    `;
  }}
`;
