const Eye = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <g clip-path="url(#clip0_2579_27818)">
      <path
        d="M23.9399 11.3105C21.4006 5.96144 17.5622 3.26947 12.4167 3.26947C7.26844 3.26947 3.43273 5.96143 0.893444 11.3132C0.791593 11.5289 0.73877 11.7644 0.73877 12.003C0.73877 12.2415 0.791593 12.477 0.893444 12.6927C3.43273 18.0418 7.27112 20.7338 12.4167 20.7338C17.5649 20.7338 21.4006 18.0418 23.9399 12.69C24.1461 12.2561 24.1461 11.7525 23.9399 11.3105ZM12.4167 18.8052C8.09612 18.8052 4.93273 16.6141 2.70148 12.0016C4.93273 7.38911 8.09612 5.19804 12.4167 5.19804C16.7372 5.19804 19.9006 7.38911 22.1318 12.0016C19.9033 16.6141 16.7399 18.8052 12.4167 18.8052ZM12.3095 7.28733C9.70594 7.28733 7.59523 9.39804 7.59523 12.0016C7.59523 14.6052 9.70594 16.7159 12.3095 16.7159C14.9131 16.7159 17.0238 14.6052 17.0238 12.0016C17.0238 9.39804 14.9131 7.28733 12.3095 7.28733ZM12.3095 15.0016C10.6515 15.0016 9.30952 13.6596 9.30952 12.0016C9.30952 10.3436 10.6515 9.00161 12.3095 9.00161C13.9676 9.00161 15.3095 10.3436 15.3095 12.0016C15.3095 13.6596 13.9676 15.0016 12.3095 15.0016Z"
        fill="#BABFC4"
      />
    </g>
    <defs>
      <clipPath id="clip0_2579_27818">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.416504)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Eye;
