import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Select as AntdSelect } from './antd';

export const Select = styled(AntdSelect)`
  ${({ theme }) => {
    return css`
      margin-top: 4px;
      border-radius: ${theme.borderRadius};
      color: ${theme.colorTextInput};
      &.ant-select-multiple {
        & div.ant-select-selector {
          height: auto;
        }
      }
      &.ant-select {
        &.ant-select-disabled {
          & div.ant-select-selector {
            &:hover,
            &:focus {
              border: 1px solid ${theme.gray4};
              box-shadow: 0 0 0 2px rgb(${theme.primaryColorRGB}/ 0%);
            }
          }
        }
        & div.ant-select-selector {
          border-radius: ${theme.borderRadius};
          padding: ${theme.paddingInput};
          min-height: 43.14px;
          & span.ant-select-selection-item {
            font-size: ${theme.fontSizeP};
            line-height: 21px;
          }
          &:hover,
          &:focus {
            border: 1px solid ${theme.secudaryColorHover};
            box-shadow: 0 0 0 2px rgb(${theme.primaryColorRGB}/ 20%);
          }
          &.ant-select-focused {
            &:hover,
            &:focus {
              border: 1px solid ${theme.secudaryColorHover};
              box-shadow: 0 0 0 2px rgb(${theme.primaryColorRGB} / 20%);
            }
          }
        }
        & .ant-select-arrow {
          font-size: 18px;
          height: 2px;
        }
        .ant-select-selection-placeholder {
          font-size: ${theme.fontSizeP};
          line-height: 21px;
        }

        &.ant-select-focused {
          &:not(.ant-select-disabled) {
            &.ant-select {
              &:not(.ant-select-customize-input) {
                .ant-select-selector {
                  border-color: ${theme.secudaryColorHover};
                  box-shadow: 0 0 0 2px rgb(${theme.primaryColorRGB} / 20%);
                }
              }
            }
          }
        }

        .ant-select-selection-search-input {
          font-size: ${theme.fontSizeP};
        }
      }
    `;
  }}
`;

export const Dropdown = styled.div`
  ${({ theme }) => {
    return css`
      .ant-select-item.ant-select-item-option.ant-select-item-option-selected,
      .ant-select-item.ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected {
        background-color: rgba(34, 144, 77, 0.2);
      }

      .ant-select-item.ant-select-item-option.ant-select-item-option-selected
        svg,
      .ant-select-item.ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected
        svg {
        fill: ${theme.primaryColor};
      }
    `;
  }}
`;
