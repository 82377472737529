const Apprentice = () => (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.05931 6.36782C4.05931 5.27083 4.34762 4.1967 4.88963 3.23683C5.43164 2.27696 6.21581 1.47705 7.17298 0.917126C8.11861 0.345775 9.20263 0.0372588 10.3097 0.0029777C11.4168 -0.0313034 12.5124 0.231518 13.4926 0.734307C14.4728 1.24852 15.3031 2.0027 15.9028 2.92829C16.5025 3.85388 16.8484 4.91659 16.9061 6.01359C16.9638 7.11058 16.7447 8.20757 16.2488 9.1903C15.7529 10.173 15.0148 11.0186 14.1038 11.6357C16.0758 12.3556 17.7825 13.6354 19.0165 15.3266C20.2389 17.0178 20.9308 19.029 21 21.1087C21 21.223 20.9885 21.3372 20.9423 21.4401C20.8962 21.5429 20.8385 21.6458 20.7578 21.7257C20.5964 21.8972 20.3773 21.9886 20.1466 22C19.916 22 19.6853 21.92 19.5239 21.76C19.3624 21.6 19.2586 21.3829 19.2471 21.1544C19.1779 18.9033 18.2208 16.755 16.5947 15.1895C14.9572 13.624 12.7776 12.7441 10.4942 12.7441C8.21088 12.7441 6.03131 13.624 4.39375 15.1895C2.75619 16.755 1.81055 18.9033 1.74136 21.1544C1.72983 21.3829 1.62603 21.5886 1.46458 21.7486C1.2916 21.8972 1.0725 21.9886 0.841857 21.9771C0.611215 21.9771 0.3921 21.8743 0.242182 21.7143C0.0807326 21.5543 0 21.3372 0 21.1087C0.0691927 19.029 0.749589 17.0178 1.98353 15.3266C3.21746 13.6354 4.92423 12.3556 6.89622 11.6357C6.03131 11.0529 5.31631 10.2644 4.83196 9.35028C4.34761 8.43611 4.08238 7.40768 4.08238 6.36782H4.05931ZM10.4827 1.73987C9.24877 1.73987 8.04943 2.23124 7.18452 3.09969C6.30808 3.96815 5.8122 5.14513 5.8122 6.36782C5.8122 7.59051 6.30808 8.77892 7.18452 9.63595C8.06096 10.5044 9.24877 10.9958 10.4827 10.9958C11.7166 10.9958 12.916 10.5044 13.7809 9.63595C14.6573 8.7675 15.1532 7.59051 15.1532 6.36782C15.1532 5.14513 14.6573 3.95672 13.7809 3.09969C12.9045 2.23124 11.7166 1.73987 10.4827 1.73987Z"
      fill="#8C8C8C"
    />
  </svg>
);

export default Apprentice;
