const Flag = () => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="44" height="44" rx="8" fill="#2C9A50" fillOpacity="0.2" />
    <path
      d="M30.625 17.1484H24.625V14.5C24.625 14.0852 24.2898 13.75 23.875 13.75H14.3125V12.8125C14.3125 12.7094 14.2281 12.625 14.125 12.625H12.8125C12.7094 12.625 12.625 12.7094 12.625 12.8125V31.1875C12.625 31.2906 12.7094 31.375 12.8125 31.375H14.125C14.2281 31.375 14.3125 31.2906 14.3125 31.1875V25H20.125V27.6484C20.125 28.0633 20.4602 28.3984 20.875 28.3984H30.625C31.0398 28.3984 31.375 28.0633 31.375 27.6484V17.8984C31.375 17.4836 31.0398 17.1484 30.625 17.1484ZM14.3125 23.3125V15.4375H22.9375V23.3125H14.3125ZM29.6875 26.7109H21.8125V25H24.4375C24.5406 25 24.625 24.9156 24.625 24.8125V18.8359H29.6875V26.7109Z"
      fill="#2C9A50"
    />
  </svg>
);

export default Flag;
