import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Table as AntdTable } from './antd';

export const Table = styled(AntdTable)`
  ${({ theme }) => {
    return css`
      &.ant-table-wrapper {
        .ant-spin {
          svg {
            fill: ${theme.primaryColor};
            width: 2em;
            height: 2em;
          }
        }
        .ant-table-pagination {
          display: flex;
          column-gap: 4px;
          padding-top: 30px;
          width: 100%;
          flex-wrap: unset;
          .ant-pagination-total-text {
            color: #838c95;
            font-family: Assistant;
            font-size: 16px;
            margin-right: auto;
          }

          & > * {
            flex: unset;
          }
        }
      }
      tr:nth-of-type(2n) td {
        background-color: #f4f2ff;
      }

      tr {
        cursor: pointer;
      }

      .ant-table-cell {
        font-family: ${theme.fontFamily};
      }
      .ant-table-cell.ant-table-column-has-sorters {
        &:focus-visible {
          color: ${theme.primaryColor};
        }
      }
      .ant-table-column-sorters {
        :hover {
          color: ${theme.primaryColor};
        }
      }
      .ant-table-column-sorter-up.active,
      .ant-table-column-sorter-down.active {
        &:hover {
          color: ${theme.primaryColor};
        }
        color: ${theme.primaryColor};
      }
    `;
  }}
`;
