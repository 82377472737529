import { useTranslation } from 'react-i18next';
import { Option } from '@/core';
import { CountryType, CountryInfo } from '@/lib/enum';
import { CaretDownOutlined } from '@/core/Icons';
import * as S from './styles';

const defaultCountries = ['BR', 'ES', 'US'];

const flags = {
  EN: 'US',
  ES: 'ES',
  PT: 'BR',
};

const CountryPicker = ({ canTranslate = true, callback = null, ...others }) => {
  let queryLang;
  let lang;
  let flag = 'br';
  if (canTranslate) {
    const params = new URLSearchParams(window.location.search);
    queryLang = `${params.get('lang') || 'BR'}`.toUpperCase();
    lang = CountryInfo[queryLang === 'EN' ? 'US' : queryLang]?.language || 'pt';
    flag = CountryInfo[flags[lang.toUpperCase()]].flag;
  }

  const { list = defaultCountries } = others;
  const { i18n } = useTranslation();
  const changeLanguage = (value, props) => {
    if (canTranslate) {
      const valueUpper = value.toUpperCase();
      const lang =
        CountryInfo[valueUpper === 'EN' ? 'US' : valueUpper].language;
      i18n.changeLanguage(lang);
      if (typeof callback === 'function') {
        callback(value);
      }
    } else {
      callback(props.dialCode);
    }
  };

  const options = list.map(item => {
    const { initial, flag, dialCode } = CountryInfo[item as CountryType];
    return (
      <Option value={initial} label={flag} key={initial} dialCode={dialCode}>
        <div className="demo-option-label-item">
          <S.Flag role="img" aria-label={initial}>
            {flag}
          </S.Flag>
          &nbsp;
        </div>
      </Option>
    );
  });

  return (
    <S.Select
      defaultValue={flag}
      optionLabelProp="label"
      onChange={(value, props) => changeLanguage(value, props)}
      suffixIcon={<CaretDownOutlined />}
      {...others}
    >
      {options}
    </S.Select>
  );
};

export default CountryPicker;
