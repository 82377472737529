import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { List as AntdList } from './antd';

export const List = styled(AntdList)`
  ${({ theme }) => {
    return css`
      .ant-list-item-meta{
        & > .ant-list-item-meta-content{
          & > .ant-list-item-meta-description {
            color: #000;
            font-size: ${theme.fontSizeP};
          }
        }
      }
    `;
  }}
`;
