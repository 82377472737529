const Erase = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3167 0.683995C10.6261 0.374668 11.0458 0.200897 11.4833 0.200897C11.9208 0.200897 12.3404 0.374668 12.6498 0.683995L17.3171 5.3502C17.6265 5.65962 17.8002 6.07922 17.8002 6.51674C17.8002 6.95427 17.6265 7.37388 17.3171 7.6833L8.30043 16.7H13.95C14.0959 16.7 14.2358 16.7579 14.3389 16.8611C14.4421 16.9642 14.5 17.1041 14.5 17.25C14.5 17.3959 14.4421 17.5358 14.3389 17.6389C14.2358 17.7421 14.0959 17.8 13.95 17.8H6.60203C6.37096 17.8125 6.13984 17.7762 5.92376 17.6934C5.70768 17.6106 5.5115 17.4831 5.34803 17.3193L0.680729 12.652C0.371402 12.3426 0.197632 11.923 0.197632 11.4854C0.197632 11.0479 0.371402 10.6283 0.680729 10.3189L10.3167 0.682895V0.683995ZM11.8721 1.46059C11.769 1.35749 11.6291 1.29956 11.4833 1.29956C11.3374 1.29956 11.1976 1.35749 11.0944 1.46059L4.09183 8.46319L9.53683 13.9082L16.5394 6.90559C16.6425 6.80245 16.7005 6.66258 16.7005 6.51674C16.7005 6.3709 16.6425 6.23104 16.5394 6.12789L11.871 1.46059H11.8721ZM8.75913 14.687L3.31413 9.242L1.45843 11.0977C1.35532 11.2008 1.2974 11.3407 1.2974 11.4865C1.2974 11.6324 1.35532 11.7723 1.45843 11.8754L6.12573 16.5427C6.22887 16.6458 6.36874 16.7037 6.51458 16.7037C6.66042 16.7037 6.80029 16.6458 6.90343 16.5427L8.75913 14.687Z"
      fill="white"
    />
  </svg>
);

export default Erase;
