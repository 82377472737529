import { FC } from 'react';
import { BreadcrumbProps as AntdBreadcrumbProps } from 'antd';
import * as S from './styles';

type BreadcrumbProps = AntdBreadcrumbProps & {
  list?: any;
};

const Breadcrumb: FC<BreadcrumbProps> = ({ list, ...others }) => {
  const items = list.map(item => ({
    ...item,
    key: `breadcrumb-item-${item.label.replace('/s', '-')}`,
    title: (
      <>
        {item?.icon && item.icon}
        {item.label}
      </>
    ),
  }));

  return <S.Breadcrumb items={items} {...others} />;
};
export default Breadcrumb;
