const Location = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6477 5.02595C16.2274 4.05693 15.6215 3.17957 14.8642 2.44336C14.1043 1.7005 13.2086 1.1109 12.2258 0.706752C11.2035 0.282645 10.1187 0.0683594 9.00035 0.0683594C7.88205 0.0683594 6.79722 0.282645 5.7749 0.70452C4.78606 1.113 3.8999 1.69782 3.13651 2.44113C2.37964 3.17775 1.77382 4.05501 1.35303 5.02372C0.917759 6.02818 0.696777 7.09514 0.696777 8.19336C0.696777 9.76925 1.07401 11.3384 1.81508 12.8518C2.41106 14.0684 3.24365 15.2536 4.29276 16.3809C6.08517 18.305 7.96463 19.4813 8.49812 19.796C8.64955 19.8855 8.82225 19.9325 8.99812 19.9322C9.17222 19.9322 9.3441 19.8876 9.49812 19.796C10.0316 19.4813 11.9111 18.305 13.7035 16.3809C14.7526 15.2559 15.5852 14.0684 16.1812 12.8518C16.9267 11.3407 17.3039 9.77372 17.3039 8.19559C17.3039 7.09738 17.0829 6.03041 16.6477 5.02595ZM9.00035 18.2201C7.52937 17.2849 2.30392 13.5818 2.30392 8.19559C2.30392 6.45675 2.99812 4.82282 4.25928 3.59068C5.5249 2.35631 7.20794 1.6755 9.00035 1.6755C10.7928 1.6755 12.4758 2.35631 13.7414 3.59291C15.0026 4.82282 15.6968 6.45675 15.6968 8.19559C15.6968 13.5818 10.4713 17.2849 9.00035 18.2201ZM9.00035 4.44336C6.83071 4.44336 5.07178 6.20229 5.07178 8.37193C5.07178 10.5416 6.83071 12.3005 9.00035 12.3005C11.17 12.3005 12.9289 10.5416 12.9289 8.37193C12.9289 6.20229 11.17 4.44336 9.00035 4.44336ZM10.7682 10.1398C10.5363 10.3723 10.2608 10.5567 9.95738 10.6824C9.65398 10.808 9.32873 10.8724 9.00035 10.8719C8.33294 10.8719 7.70571 10.6108 7.23249 10.1398C6.99995 9.90792 6.81556 9.63236 6.68991 9.32896C6.56426 9.02556 6.49984 8.70032 6.50035 8.37193C6.50035 7.70452 6.76151 7.07729 7.23249 6.60407C7.70571 6.13086 8.33294 5.87193 9.00035 5.87193C9.66776 5.87193 10.295 6.13086 10.7682 6.60407C11.2414 7.07729 11.5003 7.70452 11.5003 8.37193C11.5003 9.03934 11.2414 9.66657 10.7682 10.1398Z"
      fill="#838C95"
    />
  </svg>
);

export default Location;
