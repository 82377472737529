import React from 'react';
import * as S from './styles';

interface Props {
  className?: string;
  children?: any;
  level?: number;
  textType?: any;
  strong?: boolean;
  style?: React.CSSProperties;
  datatest?: string;
}

const Text: React.FC<Props> = ({ textType = null, children, datatest = '', ...others }) => {
  switch (textType) {
    case 'text':
      return <S.Text data-test={datatest} {...others}>{children}</S.Text>;
    case 'error':
      return <S.Error  data-test={datatest}  {...others}>{children}</S.Error>;
    case 'title':
      return <S.Title  data-test={datatest} {...others}>{children}</S.Title>;
    default:
      return <S.Paragraph  data-test={datatest} {...others}>{children}</S.Paragraph>;
  }
};

export default Text;
