import { FC } from 'react';
import { Collapse } from './antd';

export interface Content {
  key: number;
  header: string;
  content: string;
}

export interface AccordionProps {
  list?: Content[];
  ghost?: boolean;
}

const Accordion: FC<AccordionProps> = ({ list = [], ...others }) => {
  const listPane = list => {
    return list.map(item => (
      <Collapse.Panel header={item.header} key={item.key}>
        {item.content}
      </Collapse.Panel>
    ));
  };
  return <Collapse {...others}>{listPane(list)}</Collapse>;
};
export default Accordion;
