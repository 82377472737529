import { FC } from 'react';
import { Text } from '@/core';
import * as S from './styles';

const DateTimePicker: FC<any> = ({ error = null, ...others }, ref) => {
  return (
    <>
      <S.TimePicker {...others} />
      {error && <Text textType="error">{error}</Text>}
    </>
  );
};

export default DateTimePicker;
