import * as S from './styles';
import { TableProps as AntTableProps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FC } from 'react';

type RecordType = Record<string, any>;

type TableProps<T> = AntTableProps<T>;

/**
 * Customized Ant Design Table component.
 *
 * @see https://ant.design/components/table/
 */
const Table: FC<TableProps<RecordType>> = ({ ...props }) => {
  const loading =
    typeof props.loading === 'boolean'
      ? {
          indicator: <LoadingOutlined />,
          spinning: props.loading,
        }
      : props.loading;

  return <S.Table {...props} loading={loading} />;
};

export { type TableProps };
export default Table;
