import React from 'react';
import * as S from './styles';

interface Props {
  layout?: string;
  initialValues?: any;
  children?: any;
  type?: string;
  label?: string;
  valuePropName?: string;
  className?: string;
  name?: string;
  rules?: any;
}

const Form: React.FC<Props> = ({ type = null, children = null, ...others }) => {
  switch (type) {
    case 'item':
      return <S.FormItem {...others}>{children}</S.FormItem>;
    case 'list':
      return (
        <S.FormList name="formList" {...others}>
          {children}
        </S.FormList>
      );
    default:
      return <S.Form {...others}>{children}</S.Form>;
  }
};

export default Form;
