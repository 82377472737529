import Administrative from './administrative';
import Apprentice from './apprentice';
import BackIcon from './back';
import Barcode from './barcode';
import BrokenLink from './brokenLink';
import Building from './building';
import Calendar from './calendar';
import CalendarCheck from './calendarCheck';
import Check from './check';
import CheckFill from './checkFill';
import Close from './close';
import CloseCircle from './closeCircle';
import Coin from './coin';
import Csv from './csv';
import Download from './download';
import Email from './email';
import EmailSimple from './emailSimple';
import Erase from './erase';
import Exclamation from './exclamation';
import Exit from './exit';
import Eye from './eye';
import EyeInvisible from './eyeInvisible';
import Flag from './flag';
import Filter from './filter';
import Google from './google';
import Graduate from './graduate';
import Intern from './intern';
import Location from './location';
import Logo from './logo';
import Pdf from './pdf';
import Pencil from './pencil';
import PlusCircleIcon from './plusCircle';
import PlusIcon from './plus';
import Printer from './printer';
import Requirements from './requirements';
import Retweet from './retweet';
import Save from './save';
import Settings from './settings';
import Terminate from './terminate';
import Trash from './trash';
import Trophy from './trophy';
import WhatsApp from './whatsApp';
import Xlsx from './xlsx';

export {
  Administrative,
  Apprentice,
  BackIcon,
  Barcode,
  BrokenLink,
  Building,
  Calendar,
  CalendarCheck,
  Check,
  CheckFill,
  Close,
  CloseCircle,
  Coin,
  Csv,
  Download,
  Email,
  EmailSimple,
  Erase,
  Exclamation,
  Exit,
  Eye,
  EyeInvisible,
  Flag,
  Filter,
  Google,
  Graduate,
  Intern,
  Location,
  Logo,
  Pdf,
  Pencil,
  PlusCircleIcon,
  PlusIcon,
  Printer,
  Requirements,
  Retweet,
  Save,
  Settings,
  Terminate,
  Trash,
  Trophy,
  WhatsApp,
  Xlsx,

}


