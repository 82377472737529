import React, { FC } from 'react';
import { Skeleton as AntdSkeleton } from './antd';

type SkeletonProps = {
  shape?: string;
  active?: boolean;
  avatar?: boolean;
  type?: string;
};

const Skeleton: FC<SkeletonProps> = ({ type, ...others }) => {
  switch (type) {
    case 'avatar':
      return <AntdSkeleton.Avatar shape="circle" />;
    case 'button':
      return <AntdSkeleton.Button shape="default" />;
    case 'image':
      return <AntdSkeleton.Image {...others} />;
    case 'input':
      return <AntdSkeleton.Input {...others} />;
    case 'node':
      return <AntdSkeleton.Node {...others} />;
    default:
      return <AntdSkeleton {...others} />;
  }
};

export default Skeleton;
