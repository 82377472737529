const Filter = () => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2168 0.0116425H0.783745C0.23687 0.0116425 -0.104647 0.607625 0.169906 1.08307L5.36187 9.90896V15.2795C5.36187 15.6746 5.67883 15.9938 6.07169 15.9938H11.9288C12.3217 15.9938 12.6387 15.6746 12.6387 15.2795V9.90896L17.8329 1.08307C18.1052 0.607625 17.7637 0.0116425 17.2168 0.0116425ZM11.0404 14.3866H6.96009V10.9045H11.0427V14.3866H11.0404ZM11.2547 9.10539L11.0427 9.47593H6.95785L6.7458 9.10539L2.31946 1.61879H15.6811L11.2547 9.10539Z"
      fill="white"
    />
  </svg>
);

export default Filter;
