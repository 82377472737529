const Retweet = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.1875 9.93751H1.67813C1.78125 9.93751 1.86563 9.85313 1.86563 9.75001V3.76641H14.2547V5.46797C14.2547 5.51251 14.2688 5.55469 14.2969 5.58985C14.3127 5.61009 14.3323 5.62701 14.3547 5.63963C14.377 5.65224 14.4017 5.66031 14.4272 5.66336C14.4526 5.66641 14.4785 5.66438 14.5032 5.6574C14.5279 5.65041 14.551 5.63861 14.5711 5.62266L17.9297 2.98594C18.0305 2.86876 18.0141 2.74454 17.9297 2.67657L14.5711 0.0421938C14.5365 0.0145473 14.4935 -0.000350491 14.4492 6.26144e-06C14.3414 6.26144e-06 14.2523 0.0890686 14.2523 0.196881V1.89844H1.68047C0.754687 1.89844 0 2.65313 0 3.58126V9.75001C0 9.85313 0.084375 9.93751 0.1875 9.93751ZM17.8125 8.06251H16.3219C16.2188 8.06251 16.1344 8.14688 16.1344 8.25001V14.2336H3.74531V12.532C3.74531 12.4875 3.73125 12.4453 3.70312 12.4102C3.68734 12.3899 3.66769 12.373 3.64534 12.3604C3.62298 12.3478 3.59834 12.3397 3.57285 12.3366C3.54736 12.3336 3.52151 12.3356 3.49681 12.3426C3.4721 12.3496 3.44903 12.3614 3.42891 12.3773L0.0703125 15.0141C-0.0304688 15.1313 -0.0140625 15.2555 0.0703125 15.3234L3.42891 17.9578C3.46406 17.9859 3.50625 18 3.55078 18C3.65859 18 3.74766 17.9109 3.74766 17.8031V16.1016H16.3242C17.25 16.1016 18.0047 15.3469 18.0047 14.4188V8.25001C18 8.14688 17.9156 8.06251 17.8125 8.06251Z"
      fill="#8C8C8C"

    />
  </svg>
);

export default Retweet;
