import { FC } from 'react';
import { Avatar as AntdAvatar } from './antd';

type AvatarProps = {
  size?: any;
  icon?: any;
  shape?: any;
};

const Avatar: FC<AvatarProps> = ({ ...others }) => {
  return <AntdAvatar {...others} />;
};
export default Avatar;
