const checkFill = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="8" fill="#D5EBDC" />
    <path
      d="M24 11.75C17.2352 11.75 11.75 17.2352 11.75 24C11.75 30.7648 17.2352 36.25 24 36.25C30.7648 36.25 36.25 30.7648 36.25 24C36.25 17.2352 30.7648 11.75 24 11.75ZM29.291 19.9996L23.5324 27.984C23.4519 28.0963 23.3458 28.1879 23.2229 28.251C23.1 28.3141 22.9638 28.3471 22.8256 28.3471C22.6874 28.3471 22.5512 28.3141 22.4283 28.251C22.3053 28.1879 22.1992 28.0963 22.1188 27.984L18.709 23.259C18.6051 23.1141 18.709 22.9117 18.8867 22.9117H20.1691C20.448 22.9117 20.7133 23.0457 20.8773 23.2754L22.8242 25.977L27.1227 20.016C27.2867 19.7891 27.5492 19.6523 27.8309 19.6523H29.1133C29.291 19.6523 29.3949 19.8547 29.291 19.9996Z"
      fill="#2C9A50"
    />
  </svg>
);

export default checkFill;
