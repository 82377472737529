import { forwardRef, FC } from 'react';
import { Text } from '@/core';
import * as S from './styles';

interface CheckboxProps {
  children?: any;
  error?: any;
  label?: string;
  ref: any;
}

const Checkbox = forwardRef<FC, CheckboxProps>(
  ({ children, error, label, ...others }, ref) => {
    return (
      <>
        <S.Checkbox ref={ref} {...others}>
          {children}{label}
        </S.Checkbox>
        <Text textType="error">{error}</Text>
      </>
    );
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
