const Trash = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.1068 1.67648H5.92822C6.02644 1.67648 6.1068 1.59612 6.1068 1.49791V1.67648H12.8925V1.49791C12.8925 1.59612 12.9729 1.67648 13.0711 1.67648H12.8925V3.28362H14.4997V1.49791C14.4997 0.709961 13.859 0.0693359 13.0711 0.0693359H5.92822C5.14028 0.0693359 4.49965 0.709961 4.49965 1.49791V3.28362H6.1068V1.67648ZM17.3568 3.28362H1.64251C1.24742 3.28362 0.928223 3.60282 0.928223 3.99791V4.71219C0.928223 4.81041 1.00858 4.89076 1.10679 4.89076H2.45501L3.00635 16.5649C3.04206 17.326 3.67153 17.9265 4.43269 17.9265H14.5666C15.33 17.9265 15.9572 17.3283 15.993 16.5649L16.5443 4.89076H17.8925C17.9907 4.89076 18.0711 4.81041 18.0711 4.71219V3.99791C18.0711 3.60282 17.7519 3.28362 17.3568 3.28362ZM14.3947 16.3193H4.60456L4.06438 4.89076H14.9349L14.3947 16.3193Z"
      fill="white"
    />
  </svg>
);

export default Trash;
