import React from 'react';
import { Popconfirm as AntdPopconfirm } from './antd';

const Popconfirm = ({ title, children, ...others }) => {
  return (
    <AntdPopconfirm {...others} title={title}>
      {children}
    </AntdPopconfirm>
  );
};
export default Popconfirm;
