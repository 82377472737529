import { FC } from 'react';
import { Check } from '@/lib/assets/svg';
import { Item, Meta } from './antd';
import * as S from './styles';

interface ListProps {
  data: any;
  icon?: React.ReactElement;
}

const List: FC<ListProps> = ({ data, ...others }) => {
  const { icon } = others;

  return (
    <S.List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={item =>
        {
          if(item.icon){
           return <Item>
              <Meta avatar={item.icon ? item.icon : <Check />} description={item.description} />
            </Item>
          }
          return  <Item>
            <Meta avatar={icon ? icon : <Check />} description={item} />
          </Item>
        }
      }
      {...others}
    />
  );
};

export default List;
