import { forwardRef, FC } from 'react';
import { Text } from '@/core';
import * as S from './styles';

interface AutoCompleteProps {
  error?: any;
}

const AutoComplete = forwardRef<FC, AutoCompleteProps>(
  ({ error = null, ...others }, ref) => {
    return (
      <>
        <S.Autocomplete
          ref={ref}
          showSearch
          labelInValue
          filterOption={false}
          {...others}
        />
        {error && <Text textType="error">{error}</Text>}
      </>
    );
  },
);

AutoComplete.displayName = 'AutoComplete';

export default AutoComplete;
