enum CountryType {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
}

interface ICountriesObject {
  defaultDoc: string;
  dialCode: string;
  flag: string;
  i18n: string;
  initial: string;
  language: string;
  name: string;
  nationality: string;
}

/**
 * The country object.
 */
const CountryInfo: ICountryType = {
  [CountryType.BR]: {
    defaultDoc: 'rg',
    dialCode: '55',
    flag: '🇧🇷',
    i18n: 'pt-BR',
    initial: 'br',
    language: 'pt',
    name: 'Brasil',
    nationality: 'Brasileiro',
  },
  [CountryType.US]: {
    defaultDoc: 'passport',
    dialCode: '1',
    flag: '🇺🇸',
    i18n: 'en-US',
    initial: 'en',
    language: 'en',
    name: 'Estado Unidos',
    nationality: 'Americano',
  },
  [CountryType.ES]: {
    defaultDoc: 'passport',
    dialCode: '34',
    flag: '🇪🇸',
    i18n: 'es-ES',
    initial: 'es',
    language: 'es',
    name: 'Espanha',
    nationality: 'Espanhol',
  },
  [CountryType.AD]: {
    defaultDoc: 'passport',
    dialCode: '376',
    flag: '🇦🇩',
    i18n: '',
    initial: 'ad',
    language: 'en',
    name: 'Andorra',
    nationality: '',
  },
  [CountryType.AE]: {
    defaultDoc: 'passport',
    dialCode: '971',
    flag: '🇦🇪',
    i18n: '',
    initial: 'ae',
    language: 'en',
    name: 'Emirados Árabes Unidos',
    nationality: '',
  },
  [CountryType.AF]: {
    defaultDoc: 'passport',
    dialCode: '93',
    flag: '🇦🇫',
    i18n: '',
    initial: 'af',
    language: 'en',
    name: 'Afeganistão',
    nationality: '',
  },
  [CountryType.AG]: {
    defaultDoc: 'passport',
    dialCode: '1268',
    flag: '🇦🇬',
    i18n: '',
    initial: 'ag',
    language: 'en',
    name: 'Antígua e Barbuda',
    nationality: '',
  },
  [CountryType.AI]: {
    defaultDoc: 'passport',
    dialCode: '1264',
    flag: '🇦🇮',
    i18n: '',
    initial: 'ai',
    language: 'en',
    name: 'Anguila',
    nationality: '',
  },
  [CountryType.AL]: {
    defaultDoc: 'passport',
    dialCode: '355',
    flag: '🇦🇱',
    i18n: '',
    initial: 'al',
    language: 'en',
    name: 'Albânia',
    nationality: '',
  },
  [CountryType.AM]: {
    defaultDoc: 'passport',
    dialCode: '374',
    flag: '🇦🇲',
    i18n: '',
    initial: 'am',
    language: 'en',
    name: 'Armênia',
    nationality: '',
  },
  [CountryType.AO]: {
    defaultDoc: 'passport',
    dialCode: '244',
    flag: '🇦🇴',
    i18n: '',
    initial: 'ao',
    language: 'en',
    name: 'Angola',
    nationality: '',
  },
  [CountryType.AQ]: {
    defaultDoc: 'passport',
    dialCode: '672',
    flag: '🇦🇶',
    i18n: '',
    initial: 'aq',
    language: 'en',
    name: 'Antártica',
    nationality: '',
  },
  [CountryType.AR]: {
    defaultDoc: 'passport',
    dialCode: '54',
    flag: '🇦🇷',
    i18n: '',
    initial: 'ar',
    language: 'en',
    name: 'Argentina',
    nationality: '',
  },
  [CountryType.AS]: {
    defaultDoc: 'passport',
    dialCode: '1684',
    flag: '🇦🇸',
    i18n: '',
    initial: 'as',
    language: 'en',
    name: 'Samoa Americana',
    nationality: '',
  },
  [CountryType.AT]: {
    defaultDoc: 'passport',
    dialCode: '43',
    flag: '🇦🇹',
    i18n: '',
    initial: 'at',
    language: 'en',
    name: 'Áustria',
    nationality: '',
  },
  [CountryType.AU]: {
    defaultDoc: 'passport',
    dialCode: '61',
    flag: '🇦🇺',
    i18n: '',
    initial: 'au',
    language: 'en',
    name: 'Austrália',
    nationality: '',
  },
  [CountryType.AW]: {
    defaultDoc: 'passport',
    dialCode: '297',
    flag: '🇦🇼',
    i18n: '',
    initial: 'aw',
    language: 'en',
    name: 'Aruba',
    nationality: '',
  },
  [CountryType.AX]: {
    defaultDoc: 'passport',
    dialCode: '358',
    flag: '🇦🇽',
    i18n: '',
    initial: 'ax',
    language: 'en',
    name: 'Ilhas Aland',
    nationality: '',
  },
  [CountryType.AZ]: {
    defaultDoc: 'passport',
    dialCode: '994',
    flag: '🇦🇿',
    i18n: '',
    initial: 'az',
    language: 'en',
    name: 'Azerbaijão',
    nationality: '',
  },
  [CountryType.BA]: {
    defaultDoc: 'passport',
    dialCode: '387',
    flag: '🇧🇦',
    i18n: '',
    initial: 'ba',
    language: 'en',
    name: 'Bósnia e Herzegovina',
    nationality: '',
  },
  [CountryType.BB]: {
    defaultDoc: 'passport',
    dialCode: '1246',
    flag: '🇧🇧',
    i18n: '',
    initial: 'bb',
    language: 'en',
    name: 'Barbados',
    nationality: '',
  },
  [CountryType.BD]: {
    initial: 'bd',
    language: 'en',
    flag: '🇧🇩',
    name: 'Bangladesh',
    dialCode: '880',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.BE]: {
    initial: 'be',
    language: 'en',
    flag: '🇧🇪',
    name: 'Bélgica',
    dialCode: '32',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.BF]: {
    initial: 'bf',
    language: 'en',
    flag: '🇧🇫',
    name: 'Burkina Faso',
    dialCode: '226',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.BG]: {
    initial: 'bg',
    language: 'en',
    flag: '🇧🇬',
    name: 'Bulgária',
    dialCode: '359',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.BH]: {
    initial: 'bh',
    language: 'en',
    flag: '🇧🇭',
    name: 'Bahrein',
    dialCode: '973',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.BI]: {
    initial: 'bi',
    language: 'en',
    flag: '🇧🇮',
    name: 'Burundi',
    dialCode: '257',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.BJ]: {
    initial: 'bj',
    language: 'en',
    flag: '🇧🇯',
    name: 'Benim',
    dialCode: '229',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.BL]: {
    initial: 'bl',
    language: 'en',
    flag: '🇧🇱',
    name: 'São Bartolomeu',
    dialCode: '590',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.BM]: {
    initial: 'bm',
    language: 'en',
    flag: '🇧🇲',
    name: 'Bermudas',
    dialCode: '1441',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.BN]: {
    initial: 'bn',
    language: 'en',
    flag: '🇧🇳',
    name: 'Brunei Darussalam',
    dialCode: '673',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.BO]: {
    initial: 'bo',
    language: 'en',
    flag: '🇧🇴',
    name: 'Bolívia',
    dialCode: '591',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.BQ]: {
    initial: 'bq',
    language: 'en',
    flag: '🇧🇶',
    name: 'Bonaire, Santo Eustáquio e Saba',
    dialCode: '599',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.BS]: {
    initial: 'bs',
    language: 'en',
    flag: '🇧🇸',
    name: 'Bahamas',
    dialCode: '1242',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.BT]: {
    initial: 'bt',
    language: 'en',
    flag: '🇧🇹',
    name: 'Butão',
    dialCode: '975',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.BW]: {
    initial: 'bw',
    language: 'en',
    flag: '🇧🇼',
    name: 'Botsuana',
    dialCode: '267',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.BY]: {
    initial: 'by',
    language: 'en',
    flag: '🇧🇾',
    name: 'Bielorrússia',
    dialCode: '375',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.BZ]: {
    initial: 'bz',
    language: 'en',
    flag: '🇧🇿',
    name: 'Belize',
    dialCode: '501',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.CA]: {
    initial: 'ca',
    language: 'en',
    flag: '🇨🇦',
    name: 'Canadá',
    dialCode: '1',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.CC]: {
    initial: 'cc',
    language: 'en',
    flag: '🇨🇨',
    name: 'Ilhas Cocos (Keeling)',
    dialCode: '61',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.CD]: {
    initial: 'cd',
    language: 'en',
    flag: '🇨🇩',
    name: 'Congo',
    dialCode: '243',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.CF]: {
    initial: 'cf',
    language: 'en',
    flag: '🇨🇫',
    name: 'República Centro-Africana',
    dialCode: '236',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.CG]: {
    initial: 'cg',
    language: 'en',
    flag: '🇨🇬',
    name: 'Congo',
    dialCode: '242',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.CH]: {
    initial: 'ch',
    language: 'en',
    flag: '🇨🇭',
    name: 'Suíça',
    dialCode: '41',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.CI]: {
    initial: 'ci',
    language: 'en',
    flag: '🇨🇮',
    name: 'Costa do Marfim',
    dialCode: '225',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.CK]: {
    initial: 'ck',
    language: 'en',
    flag: '🇨🇰',
    name: 'Ilhas Cook',
    dialCode: '682',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.CL]: {
    initial: 'cl',
    language: 'en',
    flag: '🇨🇱',
    name: 'Chile',
    dialCode: '56',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.CM]: {
    initial: 'cm',
    language: 'en',
    flag: '🇨🇲',
    name: 'Camarões',
    dialCode: '237',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.CN]: {
    initial: 'cn',
    language: 'en',
    flag: '🇨🇳',
    name: 'China',
    dialCode: '86',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.CO]: {
    initial: 'co',
    language: 'en',
    flag: '🇨🇴',
    name: 'Colômbia',
    dialCode: '57',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.CR]: {
    initial: 'cr',
    language: 'en',
    flag: '🇨🇷',
    name: 'Costa Rica',
    dialCode: '506',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.CU]: {
    initial: 'cu',
    language: 'en',
    flag: '🇨🇺',
    name: 'Cuba',
    dialCode: '53',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.CV]: {
    initial: 'cv',
    language: 'en',
    flag: '🇨🇻',
    name: 'cabo Verde',
    dialCode: '238',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.CX]: {
    initial: 'cx',
    language: 'en',
    flag: '🇨🇽',
    name: 'Ilha do Natal',
    dialCode: '61',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.CY]: {
    initial: 'cy',
    language: 'en',
    flag: '🇨🇾',
    name: 'Chipre',
    dialCode: '357',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.CZ]: {
    initial: 'cz',
    language: 'en',
    flag: '🇨🇿',
    name: 'República Checa',
    dialCode: '420',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.DE]: {
    initial: 'de',
    language: 'en',
    flag: '🇩🇪',
    name: 'Alemanha',
    dialCode: '49',
    defaultDoc: 'passport',
    nationality: 'American',
    i18n: '',
  },
  [CountryType.DJ]: {
    initial: 'dj',
    language: 'en',
    flag: '🇩🇯',
    name: 'Djibuti',
    dialCode: '253',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.DK]: {
    initial: 'dk',
    language: 'en',
    flag: '🇩🇰',
    name: 'Dinamarca',
    dialCode: '45',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.DM]: {
    initial: 'dm',
    language: 'en',
    flag: '🇩🇲',
    name: 'Dominica',
    dialCode: '1767',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.DO]: {
    initial: 'do',
    language: 'en',
    flag: '🇩🇴',
    name: 'República Dominicana',
    dialCode: '1849',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.DZ]: {
    initial: 'dz',
    language: 'en',
    flag: '🇩🇿',
    name: 'Argélia',
    dialCode: '213',
    defaultDoc: 'passport',
    nationality: 'Australian',
    i18n: '',
  },
  [CountryType.EC]: {
    initial: 'ec',
    language: 'en',
    flag: '🇪🇨',
    name: 'Equador',
    dialCode: '593',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.EE]: {
    initial: 'ee',
    language: 'en',
    flag: '🇪🇪',
    name: 'Estônia',
    dialCode: '372',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.EG]: {
    initial: 'eg',
    language: 'en',
    flag: '🇪🇬',
    name: 'Egito',
    dialCode: '20',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.ER]: {
    initial: 'er',
    language: 'en',
    flag: '🇪🇷',
    name: 'Eritreia',
    dialCode: '291',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.ET]: {
    initial: 'et',
    language: 'en',
    flag: '🇪🇹',
    name: 'Etiópia',
    dialCode: '251',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.FI]: {
    initial: 'fi',
    language: 'en',
    flag: '🇫🇮',
    name: 'Finlândia',
    dialCode: '358',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.FJ]: {
    initial: 'fj',
    language: 'en',
    flag: '🇫🇯',
    name: 'Fiji',
    dialCode: '679',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.FK]: {
    initial: 'fk',
    language: 'en',
    flag: '🇫🇰',
    name: 'Ilhas Malvinas (Malvinas)',
    dialCode: '500',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.FM]: {
    initial: 'fm',
    language: 'en',
    flag: '🇫🇲',
    name: 'Micronésia',
    dialCode: '691',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.FO]: {
    initial: 'fo',
    language: 'en',
    flag: '🇫🇴',
    name: 'ilhas Faroe',
    dialCode: '298',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.FR]: {
    initial: 'fr',
    language: 'en',
    flag: '🇫🇷',
    name: 'França',
    dialCode: '33',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.GA]: {
    initial: 'ga',
    language: 'en',
    flag: '🇬🇦',
    name: 'Gabão',
    dialCode: '241',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.GB]: {
    initial: 'gb',
    language: 'en',
    flag: '🇬🇧',
    name: 'Reino Unido',
    dialCode: '44',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.GD]: {
    initial: 'gd',
    language: 'en',
    flag: '🇬🇩',
    name: 'Granada',
    dialCode: '1473',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.GE]: {
    initial: 'ge',
    language: 'en',
    flag: '🇬🇪',
    name: 'Geórgia',
    dialCode: '995',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.GF]: {
    initial: 'gf',
    language: 'en',
    flag: '🇬🇫',
    name: 'Guiana Francesa',
    dialCode: '594',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.GG]: {
    initial: 'gg',
    language: 'en',
    flag: '🇬🇬',
    name: 'Guernsey',
    dialCode: '44',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.GH]: {
    initial: 'gh',
    language: 'en',
    flag: '🇬🇭',
    name: 'Gana',
    dialCode: '233',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.GI]: {
    initial: 'gi',
    language: 'en',
    flag: '🇬🇮',
    name: 'Gibraltar',
    dialCode: '350',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.GL]: {
    initial: 'gl',
    language: 'en',
    flag: '🇬🇱',
    name: 'Groenlândia',
    dialCode: '299',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.GM]: {
    initial: 'gm',
    language: 'en',
    flag: '🇬🇲',
    name: 'Gâmbia',
    dialCode: '220',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.GN]: {
    initial: 'gn',
    language: 'en',
    flag: '🇬🇳',
    name: 'Guiné',
    dialCode: '224',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.GP]: {
    initial: 'gp',
    language: 'en',
    flag: '🇬🇵',
    name: 'Guadalupe',
    dialCode: '590',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.GQ]: {
    initial: 'gq',
    language: 'en',
    flag: '🇬🇶',
    name: 'Guiné Equatorial',
    dialCode: '240',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.GR]: {
    initial: 'gr',
    language: 'en',
    flag: '🇬🇷',
    name: 'Grécia',
    dialCode: '30',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.GS]: {
    initial: 'gs',
    language: 'en',
    flag: '🇬🇸',
    name: 'Geórgia do Sul',
    dialCode: '500',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.GT]: {
    initial: 'gt',
    language: 'en',
    flag: '🇬🇹',
    name: 'Guatemala',
    dialCode: '502',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.GU]: {
    initial: 'gu',
    language: 'en',
    flag: '🇬🇺',
    name: 'Guam',
    dialCode: '1671',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.GW]: {
    initial: 'gw',
    language: 'en',
    flag: '🇬🇼',
    name: 'Guiné-Bissau',
    dialCode: '245',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.GY]: {
    initial: 'gy',
    language: 'en',
    flag: '🇬🇾',
    name: 'Guiana',
    dialCode: '595',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.HK]: {
    initial: 'hk',
    language: 'en',
    flag: '🇭🇰',
    name: 'Hong Kong',
    dialCode: '852',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.HN]: {
    initial: 'hn',
    language: 'en',
    flag: '🇭🇳',
    name: 'Honduras',
    dialCode: '504',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.HR]: {
    initial: 'hr',
    language: 'en',
    flag: '🇭🇷',
    name: 'Croácia',
    dialCode: '385',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.HT]: {
    initial: 'ht',
    language: 'en',
    flag: '🇭🇹',
    name: 'Haiti',
    dialCode: '509',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.HU]: {
    initial: 'hu',
    language: 'en',
    flag: '🇭🇺',
    name: 'Hungria',
    dialCode: '36',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.ID]: {
    initial: 'id',
    language: 'en',
    flag: '🇮🇩',
    name: 'Indonésia',
    dialCode: '62',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.IE]: {
    initial: 'ie',
    language: 'en',
    flag: '🇮🇪',
    name: 'Irlanda',
    dialCode: '353',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.IL]: {
    initial: 'il',
    language: 'en',
    flag: '🇮🇱',
    name: 'Israel',
    dialCode: '972',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.IM]: {
    initial: 'im',
    language: 'en',
    flag: '🇮🇲',
    name: 'Ilha de Man',
    dialCode: '44',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.IN]: {
    initial: 'in',
    language: 'en',
    flag: '🇮🇳',
    name: 'Índia',
    dialCode: '91',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.IO]: {
    initial: 'io',
    language: 'en',
    flag: '🇮🇴',
    name: 'Território Britânico do Oceano Índico',
    dialCode: '246',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.IQ]: {
    initial: 'iq',
    language: 'en',
    flag: '🇮🇶',
    name: 'Iraque',
    dialCode: '964',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.IR]: {
    initial: 'ir',
    language: 'en',
    flag: '🇮🇷',
    name: 'Irã',
    dialCode: '98',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.IS]: {
    initial: 'is',
    language: 'en',
    flag: '🇮🇸',
    name: 'Islândia',
    dialCode: '354',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.IT]: {
    initial: 'it',
    language: 'en',
    flag: '🇮🇹',
    name: 'Itália',
    dialCode: '39',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.JE]: {
    initial: 'je',
    language: 'en',
    flag: '🇯🇪',
    name: 'Jersey',
    dialCode: '44',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.JM]: {
    initial: 'jm',
    language: 'en',
    flag: '🇯🇲',
    name: 'Jamaica',
    dialCode: '1876',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.JO]: {
    initial: 'jo',
    language: 'en',
    flag: '🇯🇴',
    name: 'Jordânia',
    dialCode: '962',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.JP]: {
    initial: 'jp',
    language: 'en',
    flag: '🇯🇵',
    name: 'Japão',
    dialCode: '81',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.KE]: {
    initial: 'ke',
    language: 'en',
    flag: '🇰🇪',
    name: 'Quênia',
    dialCode: '254',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.KG]: {
    initial: 'kg',
    language: 'en',
    flag: '🇰🇬',
    name: 'Quirguistão',
    dialCode: '996',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.KH]: {
    initial: 'kh',
    language: 'en',
    flag: '🇰🇭',
    name: 'Camboja',
    dialCode: '855',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.KI]: {
    initial: 'ki',
    language: 'en',
    flag: '🇰🇮',
    name: 'Kiribati',
    dialCode: '686',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.KM]: {
    initial: 'km',
    language: 'en',
    flag: '🇰🇲',
    name: 'Comores',
    dialCode: '269',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.KN]: {
    initial: 'kn',
    language: 'en',
    flag: '🇰🇳',
    name: 'São Cristóvão e Nevis',
    dialCode: '1869',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.KP]: {
    initial: 'kp',
    language: 'en',
    flag: '🇰🇵',
    name: 'Coréia do Norte',
    dialCode: '850',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.KR]: {
    initial: 'kr',
    language: 'en',
    flag: '🇰🇷',
    name: 'Coreia do Sul',
    dialCode: '82',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.KW]: {
    initial: 'kw',
    language: 'en',
    flag: '🇰🇼',
    name: 'Kuwait',
    dialCode: '965',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.KY]: {
    initial: 'ky',
    language: 'en',
    flag: '🇰🇾',
    name: 'Ilhas Cayman',
    dialCode: ' 345',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.KZ]: {
    initial: 'kz',
    language: 'en',
    flag: '🇰🇿',
    name: 'Cazaquistão',
    dialCode: '77',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.LA]: {
    initial: 'la',
    language: 'en',
    flag: '🇱🇦',
    name: 'República Democrática Popular do Laos',
    dialCode: '856',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.LB]: {
    initial: 'lb',
    language: 'en',
    flag: '🇱🇧',
    name: 'Líbano',
    dialCode: '961',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.LC]: {
    initial: 'lc',
    language: 'en',
    flag: '🇱🇨',
    name: 'Santa Lúcia',
    dialCode: '1758',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.LI]: {
    initial: 'li',
    language: 'en',
    flag: '🇱🇮',
    name: 'Listenstaine',
    dialCode: '423',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.LK]: {
    initial: 'lk',
    language: 'en',
    flag: '🇱🇰',
    name: 'Sri Lanka',
    dialCode: '94',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.LR]: {
    initial: 'lr',
    language: 'en',
    flag: '🇱🇷',
    name: 'Libéria',
    dialCode: '231',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.LS]: {
    initial: 'ls',
    language: 'en',
    flag: '🇱🇸',
    name: 'Lesoto',
    dialCode: '266',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.LT]: {
    initial: 'lt',
    language: 'en',
    flag: '🇱🇹',
    name: 'Lituânia',
    dialCode: '370',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.LU]: {
    initial: 'lu',
    language: 'en',
    flag: '🇱🇺',
    name: 'Luxemburgo',
    dialCode: '352',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.LV]: {
    initial: 'lv',
    language: 'en',
    flag: '🇱🇻',
    name: 'Letônia',
    dialCode: '371',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.LY]: {
    initial: 'ly',
    language: 'en',
    flag: '🇱🇾',
    name: 'Líbia',
    dialCode: '218',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.MA]: {
    initial: 'ma',
    language: 'en',
    flag: '🇲🇦',
    name: 'Marrocos',
    dialCode: '212',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.MC]: {
    initial: 'mc',
    language: 'en',
    flag: '🇲🇨',
    name: 'Mônaco',
    dialCode: '377',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.MD]: {
    initial: 'md',
    language: 'en',
    flag: '🇲🇩',
    name: 'Moldávia',
    dialCode: '373',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.ME]: {
    initial: 'me',
    language: 'en',
    flag: '🇲🇪',
    name: 'Montenegro',
    dialCode: '382',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.MF]: {
    initial: 'mf',
    language: 'en',
    flag: '🇲🇫',
    name: 'São Martinho (parte francesa)',
    dialCode: '590',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.MG]: {
    initial: 'mg',
    language: 'en',
    flag: '🇲🇬',
    name: 'Madagáscar',
    dialCode: '261',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.MH]: {
    initial: 'mh',
    language: 'en',
    flag: '🇲🇭',
    name: 'Ilhas Marshall',
    dialCode: '692',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.MK]: {
    initial: 'mk',
    language: 'en',
    flag: '🇲🇰',
    name: 'Macedônia',
    dialCode: '389',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.ML]: {
    initial: 'ml',
    language: 'en',
    flag: '🇲🇱',
    name: 'Mali',
    dialCode: '223',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.MM]: {
    initial: 'mm',
    language: 'en',
    flag: '🇲🇲',
    name: 'Mianmar',
    dialCode: '95',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.MN]: {
    initial: 'mn',
    language: 'en',
    flag: '🇲🇳',
    name: 'Mongólia',
    dialCode: '976',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.MO]: {
    initial: 'mo',
    language: 'en',
    flag: '🇲🇴',
    name: 'Macau',
    dialCode: '853',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.MP]: {
    initial: 'mp',
    language: 'en',
    flag: '🇲🇵',
    name: 'Ilhas Marianas do Norte',
    dialCode: '1670',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.MQ]: {
    initial: 'mq',
    language: 'en',
    flag: '🇲🇶',
    name: 'Martinica',
    dialCode: '596',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.MR]: {
    initial: 'mr',
    language: 'en',
    flag: '🇲🇷',
    name: 'Mauritânia',
    dialCode: '222',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.MS]: {
    initial: 'ms',
    language: 'en',
    flag: '🇲🇸',
    name: 'Montserrat',
    dialCode: '1664',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.MT]: {
    initial: 'mt',
    language: 'en',
    flag: '🇲🇹',
    name: 'Malta',
    dialCode: '356',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.MU]: {
    initial: 'mu',
    language: 'en',
    flag: '🇲🇺',
    name: 'Maurício',
    dialCode: '230',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.MV]: {
    initial: 'mv',
    language: 'en',
    flag: '🇲🇻',
    name: 'Maldivas',
    dialCode: '960',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.MW]: {
    initial: 'mw',
    language: 'en',
    flag: '🇲🇼',
    name: 'Malawi',
    dialCode: '265',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.MX]: {
    initial: 'mx',
    language: 'en',
    flag: '🇲🇽',
    name: 'México',
    dialCode: '52',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.MY]: {
    initial: 'my',
    language: 'en',
    flag: '🇲🇾',
    name: 'Malásia',
    dialCode: '60',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.MZ]: {
    initial: 'mz',
    language: 'en',
    flag: '🇲🇿',
    name: 'Moçambique',
    dialCode: '258',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.NA]: {
    initial: 'na',
    language: 'en',
    flag: '🇳🇦',
    name: 'Namíbia',
    dialCode: '264',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.NC]: {
    initial: 'nc',
    language: 'en',
    flag: '🇳🇨',
    name: 'Nova Caledônia',
    dialCode: '687',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.NE]: {
    initial: 'ne',
    language: 'en',
    flag: '🇳🇪',
    name: 'Níger',
    dialCode: '227',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.NF]: {
    initial: 'nf',
    language: 'en',
    flag: '🇳🇫',
    name: 'Ilha Norfolk',
    dialCode: '672',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.NG]: {
    initial: 'ng',
    language: 'en',
    flag: '🇳🇬',
    name: 'Nigéria',
    dialCode: '234',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.NI]: {
    initial: 'ni',
    language: 'en',
    flag: '🇳🇮',
    name: 'Nicarágua',
    dialCode: '505',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.NL]: {
    initial: 'nl',
    language: 'en',
    flag: '🇳🇱',
    name: 'Holanda',
    dialCode: '31',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.NO]: {
    initial: 'no',
    language: 'en',
    flag: '🇳🇴',
    name: 'Noruega',
    dialCode: '47',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.NP]: {
    initial: 'np',
    language: 'en',
    flag: '🇳🇵',
    name: 'Nepal',
    dialCode: '977',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.NR]: {
    initial: 'nr',
    language: 'en',
    flag: '🇳🇷',
    name: 'Nauru',
    dialCode: '674',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.NU]: {
    initial: 'nu',
    language: 'en',
    flag: '🇳🇺',
    name: 'Niue',
    dialCode: '683',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.NZ]: {
    initial: 'nz',
    language: 'en',
    flag: '🇳🇿',
    name: 'Nova Zelândia',
    dialCode: '64',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.OM]: {
    initial: 'om',
    language: 'en',
    flag: '🇴🇲',
    name: 'Omã',
    dialCode: '968',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.PA]: {
    initial: 'pa',
    language: 'en',
    flag: '🇵🇦',
    name: 'Panamá',
    dialCode: '507',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.PE]: {
    initial: 'pe',
    language: 'en',
    flag: '🇵🇪',
    name: 'Peru',
    dialCode: '51',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.PF]: {
    initial: 'pf',
    language: 'en',
    flag: '🇵🇫',
    name: 'Polinésia Francesa',
    dialCode: '689',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.PG]: {
    initial: 'pg',
    language: 'en',
    flag: '🇵🇬',
    name: 'Papua Nova Guiné',
    dialCode: '675',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.PH]: {
    initial: 'ph',
    language: 'en',
    flag: '🇵🇭',
    name: 'Filipinas',
    dialCode: '63',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.PK]: {
    initial: 'pk',
    language: 'en',
    flag: '🇵🇰',
    name: 'Paquistão',
    dialCode: '92',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.PL]: {
    initial: 'pl',
    language: 'en',
    flag: '🇵🇱',
    name: 'Polônia',
    dialCode: '48',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.PM]: {
    initial: 'pm',
    language: 'en',
    flag: '🇵🇲',
    name: 'São Pedro e Miquelon',
    dialCode: '508',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.PN]: {
    initial: 'pn',
    language: 'en',
    flag: '🇵🇳',
    name: 'Pitcairn',
    dialCode: '872',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.PR]: {
    initial: 'pr',
    language: 'en',
    flag: '🇵🇷',
    name: 'Porto Rico',
    dialCode: '1939',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.PS]: {
    initial: 'ps',
    language: 'en',
    flag: '🇵🇸',
    name: 'Território Palestino',
    dialCode: '970',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.PT]: {
    initial: 'pt',
    language: 'pt',
    flag: '🇵🇹',
    name: 'Portugal',
    dialCode: '351',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.PW]: {
    initial: 'pw',
    language: 'en',
    flag: '🇵🇼',
    name: 'Palau',
    dialCode: '680',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.PY]: {
    initial: 'py',
    language: 'en',
    flag: '🇵🇾',
    name: 'Paraguai',
    dialCode: '595',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.QA]: {
    initial: 'qa',
    language: 'en',
    flag: '🇶🇦',
    name: 'Catar',
    dialCode: '974',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.RE]: {
    initial: 're',
    language: 'en',
    flag: '🇷🇪',
    name: 'Reunião',
    dialCode: '262',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.RO]: {
    initial: 'ro',
    language: 'en',
    flag: '🇷🇴',
    name: 'Romênia',
    dialCode: '40',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.RS]: {
    initial: 'rs',
    language: 'en',
    flag: '🇷🇸',
    name: 'Sérvia',
    dialCode: '381',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.RU]: {
    initial: 'ru',
    language: 'en',
    flag: '🇷🇺',
    name: 'Rússia',
    dialCode: '7',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.RW]: {
    initial: 'rw',
    language: 'en',
    flag: '🇷🇼',
    name: 'Ruanda',
    dialCode: '250',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.SA]: {
    initial: 'sa',
    language: 'en',
    flag: '🇸🇦',
    name: 'Arábia Saudita',
    dialCode: '966',
    defaultDoc: 'passport',
    nationality: 'Armenian',
    i18n: '',
  },
  [CountryType.SB]: {
    initial: 'sb',
    language: 'en',
    flag: '🇸🇧',
    name: 'Ilhas Salomão',
    dialCode: '677',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.SC]: {
    initial: 'sc',
    language: 'en',
    flag: '🇸🇨',
    name: 'Seicheles',
    dialCode: '248',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.SD]: {
    initial: 'sd',
    language: 'en',
    flag: '🇸🇩',
    name: 'Sudão',
    dialCode: '249',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.SE]: {
    initial: 'se',
    language: 'en',
    flag: '🇸🇪',
    name: 'Suécia',
    dialCode: '46',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.SG]: {
    initial: 'sg',
    language: 'en',
    flag: '🇸🇬',
    name: 'Cingapura',
    dialCode: '65',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.SH]: {
    initial: 'sh',
    language: 'en',
    flag: '🇸🇭',
    name: 'Santa Helena, Ascensão e Tristão Da Cunha',
    dialCode: '290',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.SI]: {
    initial: 'si',
    language: 'en',
    flag: '🇸🇮',
    name: 'Eslovênia',
    dialCode: '386',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.SJ]: {
    initial: 'sj',
    language: 'en',
    flag: '🇸🇯',
    name: 'Svalbard e Jan Mayen',
    dialCode: '47',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.SK]: {
    initial: 'sk',
    language: 'en',
    flag: '🇸🇰',
    name: 'Eslováquia',
    dialCode: '421',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.SL]: {
    initial: 'sl',
    language: 'en',
    flag: '🇸🇱',
    name: 'Serra Leoa',
    dialCode: '232',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.SM]: {
    initial: 'sm',
    language: 'en',
    flag: '🇸🇲',
    name: 'São Marinho',
    dialCode: '378',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.SN]: {
    initial: 'sn',
    language: 'en',
    flag: '🇸🇳',
    name: 'Senegal',
    dialCode: '221',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.SO]: {
    initial: 'so',
    language: 'en',
    flag: '🇸🇴',
    name: 'Somália',
    dialCode: '252',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.SR]: {
    initial: 'sr',
    language: 'en',
    flag: '🇸🇷',
    name: 'Suriname',
    dialCode: '597',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.SS]: {
    initial: 'ss',
    language: 'en',
    flag: '🇸🇸',
    name: 'Sudão do Sul',
    dialCode: '211',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.ST]: {
    initial: 'st',
    language: 'en',
    flag: '🇸🇹',
    name: 'São Tomé e Príncipe',
    dialCode: '239',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.SV]: {
    initial: 'sv',
    language: 'en',
    flag: '🇸🇻',
    name: 'El Salvador',
    dialCode: '503',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.SY]: {
    initial: 'sy',
    language: 'en',
    flag: '🇸🇾',
    name: 'República Árabe da Síria',
    dialCode: '963',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.SZ]: {
    initial: 'sz',
    language: 'en',
    flag: '🇸🇿',
    name: 'Suazilândia',
    dialCode: '268',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.TC]: {
    initial: 'tc',
    language: 'en',
    flag: '🇹🇨',
    name: 'Ilhas Turcas e Caicos',
    dialCode: '1649',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.TD]: {
    initial: 'td',
    language: 'en',
    flag: '🇹🇩',
    name: 'Chade',
    dialCode: '235',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.TG]: {
    initial: 'tg',
    language: 'en',
    flag: '🇹🇬',
    name: 'Ir',
    dialCode: '228',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.TH]: {
    initial: 'th',
    language: 'en',
    flag: '🇹🇭',
    name: 'Tailândia',
    dialCode: '66',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.TJ]: {
    initial: 'tj',
    language: 'en',
    flag: '🇹🇯',
    name: 'Tajiquistão',
    dialCode: '992',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.TK]: {
    initial: 'tk',
    language: 'en',
    flag: '🇹🇰',
    name: 'Toquelau',
    dialCode: '690',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.TL]: {
    initial: 'tl',
    language: 'en',
    flag: '🇹🇱',
    name: 'Timor-Leste',
    dialCode: '670',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.TM]: {
    initial: 'tm',
    language: 'en',
    flag: '🇹🇲',
    name: 'Turcomenistão',
    dialCode: '993',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.TN]: {
    initial: 'tn',
    language: 'en',
    flag: '🇹🇳',
    name: 'Tunísia',
    dialCode: '216',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.TO]: {
    initial: 'to',
    language: 'en',
    flag: '🇹🇴',
    name: 'Tonga',
    dialCode: '676',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.TR]: {
    initial: 'tr',
    language: 'en',
    flag: '🇹🇷',
    name: 'Peru',
    dialCode: '90',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.TT]: {
    initial: 'tt',
    language: 'en',
    flag: '🇹🇹',
    name: 'Trindade e Tobago',
    dialCode: '1868',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.TV]: {
    initial: 'tv',
    language: 'en',
    flag: '🇹🇻',
    name: 'Tuvalu',
    dialCode: '688',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.TW]: {
    initial: 'tw',
    language: 'en',
    flag: '🇹🇼',
    name: 'Taiwan',
    dialCode: '886',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.TZ]: {
    initial: 'tz',
    language: 'en',
    flag: '🇹🇿',
    name: 'Tanzânia',
    dialCode: '255',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.UA]: {
    initial: 'ua',
    language: 'en',
    flag: '🇺🇦',
    name: 'Ucrânia',
    dialCode: '380',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.UG]: {
    initial: 'ug',
    language: 'en',
    flag: '🇺🇬',
    name: 'Uganda',
    dialCode: '256',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.UY]: {
    initial: 'uy',
    language: 'en',
    flag: '🇺🇾',
    name: 'Uruguai',
    dialCode: '598',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.UZ]: {
    initial: 'uz',
    language: 'en',
    flag: '🇺🇿',
    name: 'Uzbequistão',
    dialCode: '998',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.VA]: {
    initial: 'va',
    language: 'en',
    flag: '🇻🇦',
    name: 'Cidade do Vaticano',
    dialCode: '379',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.VC]: {
    initial: 'vc',
    language: 'en',
    flag: '🇻🇨',
    name: 'São Vicente e Granadinas',
    dialCode: '1784',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.VE]: {
    initial: 've',
    language: 'en',
    flag: '🇻🇪',
    name: 'Venezuela',
    dialCode: '58',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.VG]: {
    initial: 'vg',
    language: 'en',
    flag: '🇻🇬',
    name: 'Ilhas Virgens, Britânico',
    dialCode: '1284',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.VI]: {
    initial: 'vi',
    language: 'en',
    flag: '🇻🇮',
    name: 'Ilhas Virgens, EUA',
    dialCode: '1340',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.VN]: {
    initial: 'vn',
    language: 'en',
    flag: '🇻🇳',
    name: 'Vietnã',
    dialCode: '84',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.VU]: {
    initial: 'vu',
    language: 'en',
    flag: '🇻🇺',
    name: 'Vanuatu',
    dialCode: '678',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.WF]: {
    initial: 'wf',
    language: 'en',
    flag: '🇼🇫',
    name: 'Wallis e Futuna',
    dialCode: '681',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.WS]: {
    initial: 'ws',
    language: 'en',
    flag: '🇼🇸',
    name: 'Samoa',
    dialCode: '685',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.YE]: {
    initial: 'ye',
    language: 'en',
    flag: '🇾🇪',
    name: 'Iémen',
    dialCode: '967',
    defaultDoc: 'passport',
    i18n: '',
    nationality: '',
  },
  [CountryType.YT]: {
    defaultDoc: 'passport',
    dialCode: '262',
    flag: '🇾🇹',
    i18n: '',
    initial: 'yt',
    language: 'en',
    name: 'Maiote',
    nationality: '',
  },
  [CountryType.ZA]: {
    defaultDoc: 'passport',
    dialCode: '27',
    flag: '🇿🇦',
    i18n: '',
    initial: 'za',
    language: 'en',
    name: 'África do Sul',
    nationality: 'Albanian',
  },
  [CountryType.ZM]: {
    defaultDoc: 'passport',
    dialCode: '260',
    flag: '🇿🇲',
    i18n: '',
    initial: 'zm',
    language: 'en',
    name: 'Zâmbia',
    nationality: '',
  },
  [CountryType.ZW]: {
    defaultDoc: 'passport',
    dialCode: '263',
    flag: '🇿🇼',
    i18n: '',
    initial: 'zw',
    language: 'en',
    name: 'Zimbábue',
    nationality: '',
  },
};

type ICountryType = {
  readonly [key in CountryType]: ICountriesObject;
};

export { CountryInfo, CountryType };
