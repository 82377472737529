import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { AutoComplete as AntAutoComplete } from './antd';

export const Autocomplete = styled(AntAutoComplete)`
  ${({ theme }) => {
    return css`
      border-radius: ${theme.borderRadius};
      &.ant-select {
        & div.ant-select-selector {
          border-radius: ${theme.borderRadius};
          padding: ${theme.paddingInput};
          height: 40px;
          & span.ant-select-selection-item {
            line-height: 24px;
          }
          & span.ant-select-selection-placeholder {
            line-height: 24px;
          }
          .ant-select-selection-search-input {
            padding-top: 8px;
          }
        }
      }
      & span.ant-select-selection-placeholder {
        line-height: 24px;
      }
    `;
  }}
`;
