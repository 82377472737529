import { FC, useState } from 'react';
import { Icon, Text } from '@/core';
import { CloseCircleOutlined } from '@/core/Icons'
import * as S from './styles';

interface MessageProps {
  children: any;
  duration?: number;
  footer?: any;
  visible?: any;
  onCancel?: any;
  cancelText?: string;
  width?: number;
}

const Message: FC<MessageProps> = ({
  width = 250,
  children = null,
  duration = 5000,
  ...others
}) => {
  const [isVisible, setIsVisible] = useState(true);

  setTimeout(() => {
    setIsVisible(false);
  }, duration);

  return (
    <S.Message
      width={width}
      {...others}
      closable={false}
      mask
      visible={isVisible}
    >
      <Text textType="text">
        <span>
          <CloseCircleOutlined className="CloseCircleOutlined" />
        </span>{' '}
        {children}
      </Text>
    </S.Message>
  );
};

export default Message;
