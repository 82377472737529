import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Spin as AntdSpin } from './antd';

export const Spin = styled(AntdSpin)`
  ${({ theme }) => {
    return css`
      svg {
        fill: ${theme.primaryColor};
        background: transparent;
        border-radius: 80px;
        width: 80px;
        height: 80px;
      }
    `;
  }}
`;
