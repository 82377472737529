const Calendar = () => (
  <svg
    width="52"
    height="53"
    viewBox="0 0 52 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="0.5"
      width="52"
      height="52"
      rx="8"
      fill="#2C9A50"
      fillOpacity="0.2"
    />
    <path
      d="M37.5 16.25H32.25V14.25C32.25 14.1125 32.1375 14 32 14H30.25C30.1125 14 30 14.1125 30 14.25V16.25H22V14.25C22 14.1125 21.8875 14 21.75 14H20C19.8625 14 19.75 14.1125 19.75 14.25V16.25H14.5C13.9469 16.25 13.5 16.6969 13.5 17.25V38C13.5 38.5531 13.9469 39 14.5 39H37.5C38.0531 39 38.5 38.5531 38.5 38V17.25C38.5 16.6969 38.0531 16.25 37.5 16.25ZM36.25 36.75H15.75V24.875H36.25V36.75ZM15.75 22.75V18.5H19.75V20C19.75 20.1375 19.8625 20.25 20 20.25H21.75C21.8875 20.25 22 20.1375 22 20V18.5H30V20C30 20.1375 30.1125 20.25 30.25 20.25H32C32.1375 20.25 32.25 20.1375 32.25 20V18.5H36.25V22.75H15.75Z"
      fill="#2C9A50"
    />
  </svg>
);

export default Calendar;
