import { Select as AntdSelect } from 'antd';
import styled from '@emotion/styled';

export const Flag = styled.span`
  font-size: 22px;
`;

export const Select = styled(AntdSelect)`
  .ant-select-selector {
    background-color: inherit !important;
    border: inherit !important;
  }

  .ant-select-selector::after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #000;
    clear: both;
    content: '';
    height: 0;
    left: 18px;
    position: absolute;
    top: 0;
    width: 0;
  }

  .ant-select-item-option {
    width: 100%;
  }

  & .ant-select-arrow {
    font-size: 18px;
    height: 2px;
  }
`;
