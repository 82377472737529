import { useEffect } from 'react';
import { notification } from './antd';
import { setNotification } from '@/lib/store/config';
import { useDispatch } from 'react-redux';

const initialState = {
  description: '',
  duration: 0,
  message: '',
  show: false,
  type: ''
};

const Notification = ({ type, description, onClose, ...params }) => {
  const [api, contextHolder] = notification.useNotification();

  const dispatch = useDispatch();

  useEffect(() => {

    api[type]({
      description,
      onClose: () => dispatch(setNotification(initialState)),
      ...params,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return contextHolder;
};

export default Notification;
