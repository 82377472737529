type BenefitsObject = {
  key: string;
  icon: string;
}

/**
 * The benefits object.
 */
const Benefits: BenefitsObject[] = [
  { icon: "local_cafe", key: "Vale-lanche" },
  { icon: "add_to_photos", key: "Bônus" },
  { icon: "pool", key: "Clube da empresa" },
  { icon: "local_pharmacy", key: "Convênio farmácia" },
  { icon: "language", key: "Curso de Idiomas" },
  { icon: "explicit", key: "Estacionamento no Local" },
  { icon: "library_books", key: "Programa de treinamento" },
  { icon: "sentiment_satisfied_alt", key: "Programas de qualidade de vida no trabalho" },
  { icon: "fastfood", key: "Refeição no local" },
  { icon: "airport_shuttle", key: "Ônibus fretado" },
  { icon: "av_timer", key: "Horário flexível" },
  { icon: "spa", key: "Quick massage" },
  { icon: "accessibility_new", key: "Ginástica laboral" },
  { icon: "pool", key: "Reembolso de práticas esportivas" },
  { icon: "local_bar", key: "Eventos para celebrar e integrar" },
  { icon: "directions_bus", key: "Vale-transporte" },
  { icon: "restaurant_menu", key: "Espaço para refeição" },
  { icon: "restaurant", key: "Vale-refeição" },
  { icon: "local_grocery_store", key: "Vale-alimentação" },
  { icon: "exposure_plus_1", key: "13º bolsa-auxílio" },
  { icon: "assignment_ind", key: "Possibilidade de efetivação" },
  { icon: "home", key: "Previdência privada" },
  { icon: "directions_car", key: "Reembolso pedágio" },
  { icon: "school", key: "Pagamento de MBA" },
  { icon: "directions_car", key: "Veículo fornecido pela empresa" },
  { icon: "attach_money", key: "Reembolso de despesas de viagem" },
  { icon: "language", key: "Curso de Inglês" },
  { icon: "local_hospital", key: "Accident insurance" },
  { icon: "insert_chart_outlined", key: "Participação dos lucros" },
  { icon: "monetization_on", key: "Remuneración mensal MXN 8000" },
  { icon: "attach_money", key: "Incremento de beca MXN 500 cada 6 meses" },
  { icon: "beach_access", key: "Recesso remunerado" },
  { icon: "local_movies", key: "Carteirinha de cinema free com direito acompanhante" },
  { icon: "card_giftcard", key: "Cartão multibenefícios" },
  { icon: "exposure_plus_2", key: "14º bolsa-auxílio" },
  { icon: "local_pharmacy", key: "Reembolso Farmácia" },
  { icon: "theaters", key: "Convênio SESC" },
  { icon: "monetization_on", key: "Bolsa em pesos argentinos" },
  { icon: "laptop_chromebook", key: "Plataforma de aprendizagem online de idiomas gratuita" },
  { icon: "school", key: "Parceria de descontos com universidades e outras IEs" },
  { icon: "transfer_within_a_station", key: "Auxílio mobilidade" },
  { icon: "subway", key: "Vans para acesso ao metrô ou trem" },
  { icon: "directions_run", key: "Programa saúde e bem estar" },
  { icon: "call_made", key: "Programa de desenvolvimento" },
  { icon: "school", key: "Pagamento de pós graduação" },
  { icon: "sync", key: "Job rotation" },
  { icon: "security", key: "Seguro de vida" },
  { icon: "monetization_on", key: "Desconto nos produtos da empresa" },
  { icon: "notifications", key: "Programa de participação nos resultados" },
  { icon: "home", key: "Moradia  alojamento" },
  { icon: "local_shipping", key: "Ajuda de custo para mudança" },
  { icon: "restaurant", key: "Programa de Orientação Nutricional" },
  { icon: "pets", key: "Convênio Pet" },
  { icon: "directions_car", key: "Auxílio transporte" },
  { icon: "casino", key: "Sala de jogos e descanso" },
  { icon: "", key: "Reembolso de despesas com viagem" },
  { icon: "local_hospital", key: "Assistência médica" },
  { icon: "business_center", key: "Assistência odontológica" },
  { icon: "shopping_cart", key: "Cesta básica" },
  { icon: "healing", key: "Serviço médico ambulatorial" },
  { icon: "business_center", key: "Cesta de natal" },
  { icon: "child_friendly", key: "Auxílio creche" },
  { icon: "school", key: "Kit escolar" },
  { icon: "home", key: "Auxílio home office" },
  { icon: "attach_money", key: "Previdência privada" },
  { icon: "credit_card", key: "Cooperativa de crédito" },
  { icon: "", key: "Ajuda de custo para combustível" },
  { icon: "card_giftcard", key: "Cartão de natal" },
  { icon: "local_pharmacy", key: "Campanha de vacinação" },
  { icon: "today", key: "Day off de aniversário" },
  { icon: "school", key: "Universidade corporativa" },
  { icon: "attach_money", key: "13º salário" },
  { icon: "school", key: "Auxílio-educação" },
  { icon: "", key: "Carro" },
  { icon: "fitness_center", key: "Wellhub" },
  { icon: "home", key: "Auxílio moradia" },
  { icon: "school", key: "Auxílio faculdade" },
  { icon: "fitness_center", key: "TotalPass" },
  { icon: "local_bar", key: "Short friday" },
  { icon: "local_grocery_store", key: "Auxilio de Alimentación" },
  { icon: "monetization_on", key: "Bolsa em pesos colombianos" },
  { icon: "attach_money", key: "Auxilio Internet" },
  { icon: "", key: "Cartão Carrefour 5% de desconto" },
  { icon: "fastfood", key: "Vale-presente iFood" },
  { icon: "", key: "Asignación de internet de 30 USD" },
  { icon: "show_chart", key: "Trilha de desenvolvimento" },
  { icon: "", key: "Seguro médico" },
  { icon: "", key: "Aguinaldo proporcional a los 6 meses" },
  { icon: "", key: "Fondo de ahorro al 13" },
  { icon: "", key: "Vales de despensa al 10" },
  { icon: "", key: "Plan de gastos médicos mayores" },
  { icon: "", key: "Curso de inglés en línea" },
  { icon: "", key: "Contrato directo con Corteva" },
  { icon: "", key: "Aguinaldo 30 días" },
  { icon: "", key: "Fondo de ahorro al 13" },
  { icon: "", key: "Prima vacacional al 98" },
  { icon: "", key: "14 días de vacaciones al año" },
  { icon: "", key: "Horario flexible" },
  { icon: "local_pizza", key: "Meal voucher" },
  { icon: "local_hospital", key: "Pólizas de vida" },
  { icon: "work", key: "Bono de comunicación" },
  { icon: "fitness_center", key: "Convenio gimnasio" },
  { icon: "healing", key: "Pólizas Funeraria" },
  { icon: "healing", key: "Pólizas de Salud" },
  { icon: "healing", key: "Pólizas Odontológicas" },
  { icon: "", key: "Benefício viagem" },
  { icon: "local_hospital", key: "Suporte psicológico, jurídico e financeiro" },
  { icon: "remove_red_eye", key: "Reembolso Ótica" },
  { icon: "workspace_premium", key: "Programa de reconhecimento" },
  { icon: "redeem", key: "Vale presente de aniversário" }
];

export { Benefits };

