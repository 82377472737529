import { Text } from '@/core';
import { CaretDownOutlined, LoadingOutlined } from '@/core/Icons';
import { Option, SelectProps as AntdSelectProps } from './antd';
import * as S from './styles';

type SelectProps = AntdSelectProps & {
  error?: any;
  fetching?: boolean;
  name?: string;
};

const Select = ({ error = null, fetching = false, ...others }: SelectProps) => {
  return (
    <>
      <S.Select
        suffixIcon={<CaretDownOutlined />}
        notFoundContent={fetching ? <LoadingOutlined spin /> : null}
        dropdownRender={children => <S.Dropdown>{children}</S.Dropdown>}
        {...others}
      />
      {error && <Text textType="error">{error}</Text>}
    </>
  );
};

Select.displayName = 'Select';

export { Option };

export default Select;
