const CalendarCheck = () => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="44" height="44" rx="8" fill="#2C9A50" fillOpacity="0.2" />
    <path
      d="M30.625 14.3125H26.6875V12.8125C26.6875 12.7094 26.6031 12.625 26.5 12.625H25.1875C25.0844 12.625 25 12.7094 25 12.8125V14.3125H19V12.8125C19 12.7094 18.9156 12.625 18.8125 12.625H17.5C17.3969 12.625 17.3125 12.7094 17.3125 12.8125V14.3125H13.375C12.9602 14.3125 12.625 14.6477 12.625 15.0625V30.625C12.625 31.0398 12.9602 31.375 13.375 31.375H30.625C31.0398 31.375 31.375 31.0398 31.375 30.625V15.0625C31.375 14.6477 31.0398 14.3125 30.625 14.3125ZM29.6875 29.6875H14.3125V16H17.3125V17.125C17.3125 17.2281 17.3969 17.3125 17.5 17.3125H18.8125C18.9156 17.3125 19 17.2281 19 17.125V16H25V17.125C25 17.2281 25.0844 17.3125 25.1875 17.3125H26.5C26.6031 17.3125 26.6875 17.2281 26.6875 17.125V16H29.6875V29.6875ZM26.125 19.8438H24.8312C24.7117 19.8438 24.5969 19.9023 24.5266 19.9984L20.9898 24.8688L19.4734 22.7828C19.4031 22.6867 19.2906 22.6281 19.1688 22.6281H17.875C17.7227 22.6281 17.6336 22.8016 17.7227 22.9258L20.6852 27.0039C20.7202 27.0519 20.766 27.0909 20.819 27.1177C20.8719 27.1446 20.9305 27.1586 20.9898 27.1586C21.0492 27.1586 21.1078 27.1446 21.1607 27.1177C21.2137 27.0909 21.2595 27.0519 21.2945 27.0039L26.2773 20.1437C26.3664 20.0172 26.2773 19.8438 26.125 19.8438Z"
      fill="#2C9A50"
    />
  </svg>
);

export default CalendarCheck;
