import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Tabs as AntdTabs } from './antd';


export const Tab = styled(AntdTabs)`
  ${({ theme }) => {
    return css`
      .ant-tabs-nav {
        margin: 0;
      }
      .ant-tabs-tab {
        .ant-tabs-tab-btn {
          font-size: ${theme.fontSizeLabelTab};
          font-weight: 400;
        }
        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: ${theme.primaryColor};
            font-weight: 600;
          }
        }
        &:hover {
          .ant-tabs-tab-btn {
            color: ${theme.primaryColor};
          }
        }
      }
    `;
  }}
`;



export const ContentTab = styled(AntdTabs.TabPane)`
  ${({ theme }) => {
    return css`
    padding: 0;
    `;
  }}
`;