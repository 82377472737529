const google = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.1 9.6498H23.1334V9.6H12.3334V14.4H19.1152C18.1258 17.1942 15.4672 19.2 12.3334 19.2C8.35717 19.2 5.13337 15.9762 5.13337 12C5.13337 8.0238 8.35717 4.8 12.3334 4.8C14.1688 4.8 15.8386 5.4924 17.11 6.6234L20.5042 3.2292C18.361 1.2318 15.4942 0 12.3334 0C5.70637 0 0.333374 5.373 0.333374 12C0.333374 18.627 5.70637 24 12.3334 24C18.9604 24 24.3334 18.627 24.3334 12C24.3334 11.1954 24.2506 10.41 24.1 9.6498Z"
      fill="#fbbc05"
    />
    <path
      d="M1.71704 6.4146L5.65964 9.306C6.72644 6.6648 9.31004 4.8 12.3334 4.8C14.1688 4.8 15.8386 5.4924 17.11 6.6234L20.5042 3.2292C18.361 1.2318 15.4942 0 12.3334 0C7.72424 0 3.72704 2.6022 1.71704 6.4146Z"
      fill="#ea4335"
    />
    <path
      d="M12.3334 24C15.433 24 18.2494 22.8138 20.3788 20.8848L16.6648 17.742C15.4195 18.689 13.8979 19.2012 12.3334 19.2C9.21219 19.2 6.56199 17.2098 5.56359 14.4324L1.65039 17.4474C3.63639 21.3336 7.66959 24 12.3334 24Z"
      fill="#34a853"
    />
    <path
      d="M24.1 9.64979H23.1334V9.59999H12.3334V14.4H19.1152C18.6419 15.7298 17.7894 16.8919 16.663 17.7426L16.6648 17.7414L20.3788 20.8842C20.116 21.123 24.3334 18 24.3334 12C24.3334 11.1954 24.2506 10.41 24.1 9.64979Z"
      fill="#4285f4"
    />
  </svg>
);

export default google;
