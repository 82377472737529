const download = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.831 13.9861C10.8511 14.0117 10.8767 14.0324 10.9059 14.0466C10.9352 14.0609 10.9673 14.0683 10.9998 14.0683C11.0323 14.0683 11.0644 14.0609 11.0936 14.0466C11.1229 14.0324 11.1485 14.0117 11.1685 13.9861L14.1685 10.1905C14.2784 10.0512 14.1793 9.84498 13.9998 9.84498H12.015V0.780692C12.015 0.662835 11.9185 0.566406 11.8007 0.566406H10.1935C10.0757 0.566406 9.97925 0.662835 9.97925 0.780692V9.8423H7.99979C7.82033 9.8423 7.72122 10.0486 7.83104 10.1878L10.831 13.9861ZM20.8034 13.0486H19.1962C19.0784 13.0486 18.9819 13.145 18.9819 13.2628V17.3878H3.01765V13.2628C3.01765 13.145 2.92122 13.0486 2.80336 13.0486H1.19622C1.07836 13.0486 0.981934 13.145 0.981934 13.2628V18.5664C0.981934 19.0405 1.36497 19.4236 1.83908 19.4236H20.1605C20.6346 19.4236 21.0176 19.0405 21.0176 18.5664V13.2628C21.0176 13.145 20.9212 13.0486 20.8034 13.0486Z"
      fill="white"
    />
  </svg>
);

export default download;
