import { forwardRef, FC } from 'react';
import { Text } from '@/core';
import * as S from './styles';

interface RadioProps {
  error?: any;
  options?: Array<{ value: any; label: string }>;
  optionType?: string;
  valueDefault?: string | number;
}

const Radio = forwardRef<FC, RadioProps>(({ error, ...others }, ref) => {
  return (
    <>
      <S.RadioGroup ref={ref} {...others} />
      {error && <Text textType="error">{error}</Text>}
    </>
  );
});

Radio.displayName = 'Radio';

export default Radio;
