import styled from '@emotion/styled';
import { Breadcrumb as AntdBreadcrumb } from './antd';

export const Breadcrumb = styled(AntdBreadcrumb)`
  &.ant-breadcrumb {
    margin-bottom: 24px;
    ol {
      li {
        &:last-child {
          a {
            color: ${({ theme }) => theme.primaryColor};
            font-weight: 600;
          }
        }
        a {
          text-decoration: none;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          &:hover {
            text-decoration: underline;
            color: ${({ theme }) => theme.primaryColor};
            background: none;
          }
        }
      }
    }
  }
`;
