import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Menu as AntMenu } from './antd';

export const Menu = styled(AntMenu)`
${({ theme }) => {
  return css`
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontSizeP};
    color: ${theme.gray10};
    line-height: 24px;
    &.ant-menu-root:focus-visible {
      box-shadow: 0 0 0 0;
    }

    .ant-menu-submenu-selected {
      color: ${theme.primaryColorHover};
      & > .ant-menu-submenu-title {
        & > svg {
          &:not(.should-stroke){
            path {
              fill: ${theme.primaryColorHover};
            }
          }
          &.should-stroke{
            path {
              stroke: ${theme.primaryColorHover};
              fill: ${theme.primaryColorHover};
            }
          }
          rect {
            stroke: ${theme.primaryColorHover};
          }
        }
      }
    }

    & > .ant-menu-submenu-inline {
      padding-left: 40px;
      & > .ant-menu-submenu-title {
        margin-top: 0;
        margin-bottom: 0;
        height: 56px;
        margin-left: -40px;
        padding-right: 0 !important;
        padding-left: 40px !important;
        width: calc(100% + 40px);
      }
    }
    .ant-menu-item{
      margin-top: 0;
      margin-bottom: 0;
      height: 56px;
    }
    .ant-menu-submenu-title {
      &:hover {
        & > .ant-menu-title-content,
        & > .ant-menu-submenu-arrow {
          color: ${theme.primaryGreen};
        }
        & > svg {
          &:not(.should-stroke){
            path {
              fill: ${theme.primaryGreen};
            }
          }
          &.should-stroke{
            path {
              stroke: ${theme.primaryGreen};
              fill: ${theme.primaryGreen};
            }
          }
          rect {
            stroke: ${theme.primaryGreen};
          }
        }
      }
      & > i.ant-menu-submenu-arrow {
        color: ${theme.gray10};
      }
      & > .ant-menu-title-content {
        font-family: ${theme.fontFamily};
        font-size: ${theme.fontSizeP};
        font-weight: 600;
      }
    }
    li.ant-menu-submenu-open {
      .ant-menu-submenu-title {
        & > .ant-menu-title-content,
        & > .ant-menu-submenu-arrow {
          color: ${theme.primaryGreen};
        }
        & > svg {
          &:not(.should-stroke){
            path {
              fill: ${theme.primaryGreen};
            }
          }
          &.should-stroke{
            path {
              stroke: ${theme.primaryGreen};
              fill: ${theme.primaryGreen};
            }
          }
          rect {
            stroke: ${theme.primaryGreen};
          }
        }
      }
    }
    .ant-menu-sub {
      background-color: ${theme.whiteBackground};
      .ant-menu-item {
        font-family: ${theme.fontFamily};
        font-size: ${theme.fontSizeP};
        color: ${theme.gray10};
        height: 56px;
        margin: 0 -40px;
        font-weight: 600;
        padding-left: 80px !important;
        width: calc(100% + 40px);
        &:hover {
          color: ${theme.primaryGreen};
        }
        &:active {
          background: ${theme.secudaryColorHover};
          color: ${theme.primaryGreen};
        }
      }
    }
    .ant-menu-submenu .ant-menu-submenu-inline .ant-menu-submenu-open {
      background: ${theme.secudaryColorHover};

    }
    .ant-menu-item:active, .ant-menu-submenu-title:active {
      background: ${theme.secudaryColorHover};
    }
    .ant-menu-submenu-title {
      & > .ant-menu-submenu-arrow {
        color: rgba(0,0,0,0.85);
      }
      &:active {
        background: ${theme.secudaryColorHover};
        & > .ant-menu-title-content,
        & > .ant-menu-submenu-arrow {
          color: ${theme.primaryGreen};
        }
        & > svg {
          &:not(.should-stroke){
            path {
              fill: ${theme.primaryGreen};
            }
          }
          &.should-stroke{
            path {
              stroke: ${theme.primaryGreen};
              fill: ${theme.primaryGreen};
            }
          }
          rect {
            stroke: ${theme.primaryGreen};
          }
        }
      }
    }
    svg {
      margin-right: 15px;
      width: 24px;
      height: 24px;
    }
    & > .ant-menu-item {
      font-family: ${theme.fontFamily};
      font-size: ${theme.fontSizeP};
      font-weight: 600;
      line-height: 24px;
      padding-left: 40px !important;
      &:hover {
        & > .ant-menu-title-content,
        & > .ant-menu-submenu-arrow {
          color: ${theme.primaryGreen};
        }
        & > svg {
          &:not(.should-stroke){
            path {
              fill: ${theme.primaryGreen};
            }
          }
          &.should-stroke{
            path {
              stroke: ${theme.primaryGreen};
            }
          }
        }
      }
      &:active {
        background: ${theme.secudaryColorHover};
        color: ${theme.primaryGreen};
      }
      &.ant-menu-item-selected {
        background: ${theme.secudaryColorHover};
        color: ${theme.primaryGreen};
        & > svg {
          &:not(.should-stroke){
            path {
              fill: ${theme.primaryGreen};
            }
          }
          path {
            stroke: ${theme.primaryGreen};
          }
        }
        &:after {
          border: 0;
        }
      }
    }
  `;
}}
`;
