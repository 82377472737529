import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Dropdown as AntdDropdown} from './antd';

export const Dropdown = styled(AntdDropdown)`
  ${({ theme }) => {
    return css`
      .ant-select-item.ant-select-item-option.ant-select-item-option-active {
        color:${theme.primaryColor};
      }
    `;
  }}
`;
