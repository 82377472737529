import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TimePicker as AntTimePicker } from './antd';

export const TimePicker = styled(AntTimePicker)`
  ${({ theme }) => {
    return css`
      border-radius: ${theme.borderRadius};
      border: 1px solid ${theme.gray4};
      color: ${theme.colorTextInput};
      font-size: ${theme.fontSizeP};
      margin-top: 4px;
      padding: ${theme.paddingInput};
      &:hover,
      &:focus {
        border: 1px solid ${theme.secudaryColorHover};
        box-shadow: 0 0 0 2px rgba(${theme.primaryColorRGB} / 20%);
      }
      .ant-form-item-control-input-content {
        ${theme.borderRadiusTest}
      }
      &.ant-input-disabled {
        &:hover,
        &:focus {
          border: 1px solid ${theme.gray4};
          box-shadow: 0 0 0 2px rgba(${theme.primaryColorRGB} / 0%);
        }
      }
      .ant-input{
        &:focus {
          border: 1px solid ${theme.secudaryColorHover};
          box-shadow: 0 0 0 2px rgba(${theme.primaryColorRGB} / 20%);
        }
      }
    `;
  }}
`;