import { FC } from 'react';
import * as S from './styles';
import { MenuProps as AntdMenuProps } from 'antd';

// Remove antd theme prop to avoid conflicts with emotion theme.
type MenuProps = Omit<AntdMenuProps, 'theme'>;

const defaultProps: Partial<MenuProps> = {
  mode: 'inline',
};

const Menu: FC<MenuProps> = ({ ...props }: MenuProps) => {
  return <S.Menu {...defaultProps} {...props} />;
};

export type { AntdMenuProps as MenuProps };
export default Menu;
