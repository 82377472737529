const exclamation = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 0.5C5.20156 0.5 0.5 5.20156 0.5 11C0.5 16.7984 5.20156 21.5 11 21.5C16.7984 21.5 21.5 16.7984 21.5 11C21.5 5.20156 16.7984 0.5 11 0.5ZM11 19.7188C6.18594 19.7188 2.28125 15.8141 2.28125 11C2.28125 6.18594 6.18594 2.28125 11 2.28125C15.8141 2.28125 19.7188 6.18594 19.7188 11C19.7188 15.8141 15.8141 19.7188 11 19.7188Z"
      fill="#FAAD14"
    />
    <path
      d="M9.87463 15.125C9.87463 15.4234 9.99316 15.7095 10.2041 15.9205C10.4151 16.1315 10.7013 16.25 10.9996 16.25C11.298 16.25 11.5842 16.1315 11.7951 15.9205C12.0061 15.7095 12.1246 15.4234 12.1246 15.125C12.1246 14.8266 12.0061 14.5405 11.7951 14.3295C11.5842 14.1185 11.298 14 10.9996 14C10.7013 14 10.4151 14.1185 10.2041 14.3295C9.99316 14.5405 9.87463 14.8266 9.87463 15.125ZM10.4371 12.5H11.5621C11.6653 12.5 11.7496 12.4156 11.7496 12.3125V5.9375C11.7496 5.83438 11.6653 5.75 11.5621 5.75H10.4371C10.334 5.75 10.2496 5.83438 10.2496 5.9375V12.3125C10.2496 12.4156 10.334 12.5 10.4371 12.5Z"
      fill="#FAAD14"
    />
  </svg>
);

export default exclamation;
