import { forwardRef, FC } from 'react';
import moment from 'moment';
import { Text } from '@/core';
import * as S from './styles';

interface DateTimePickerProps {
  error?: any;
  format?: any;
  name?: any;
  value?: any;
  onChange?: any;
}

const DateTimePicker = forwardRef<FC, DateTimePickerProps>(
  ({ error = null, ...others }, ref) => {
    return (
      <>
        <S.Datepicker
          ref={ref}
          {...others}
          dropdownClassName="custom-datepicker"
          // eslint-disable-next-line react/no-unstable-nested-components
          panelRender={children => <S.Panel>{children}</S.Panel>}
        />
        {error && <Text textType="error">{error}</Text>}
      </>
    );
  },
);

DateTimePicker.displayName = 'DateTimePicker';

export default DateTimePicker;
