import mixins from '@/lib/styles/mixins';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Paragraph as AntdParagraph,
  Text as AntdText,
  Title as AntdTitle,
} from './antd';

export const Paragraph = styled(AntdParagraph)`
  ${({ theme }) => {
    return css`
      font-family: ${theme.fontFamily};
      font-size: ${theme.fontSizeParagraph};
      &.ant-typography {
        color: ${theme.colorText};
        font-family: ${theme.fontFamily}, sans-serif;
      }
    `;
  }}
`;

export const Text = styled(AntdText)`
  ${({ theme }) => {
    return css`
      font-family: ${theme.fontFamily}, sans-serif;
      font-size: ${theme.fontSizeParagraph};
      &.ant-typography {
        color: ${theme.colorText};
        font-family: ${theme.fontFamily}, sans-serif;
      }
    `;
  }}
`;

export const Error = styled(AntdText)`
  color: var(--theme-error-color);
  display: block;
  font-size: 12px;
`;

export const Title = styled(AntdTitle)`
  ${({ theme, ...others }) => {
    return css`
      &.ant-typography {
        color: ${theme.colorText};
        font-size: ${mixins.fontSize(others.level, theme)};
        font-family: ${theme.fontFamily}, sans-serif;
      }
    `;
  }}
`;
