import { FC } from 'react';
import * as S from './styles';

interface PaginationProps {
  current?: number;
  defaultCurrent?: number;
  defaultPageSize?: number;
  onChange?: Function;
  onShowSizeChange?: Function;
  pageSize?: number;
  pageSizeOptions?: Array<String>;
  totalItems?: number;
}

const Pagination: FC<PaginationProps> = ({ totalItems, ...others }) => {
  return <S.Pagination {...others} total={totalItems} showTotal={total => `Registros encontrados: ${total}`} />;
};
export default Pagination;
