const printer = () => (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4063 8.96763H18.3795C18.2665 8.96763 18.1741 9.06406 18.1741 9.18192V10.2533C18.1741 10.3712 18.2665 10.4676 18.3795 10.4676H19.4063C19.5192 10.4676 19.6116 10.3712 19.6116 10.2533V9.18192C19.6116 9.06406 19.5192 8.96763 19.4063 8.96763ZM20.2277 6.18192H17.1473V0.503348C17.1473 0.385491 17.0549 0.289062 16.942 0.289062H6.05804C5.94509 0.289062 5.85268 0.385491 5.85268 0.503348V6.18192H2.77232C1.63772 6.18192 0.71875 7.14085 0.71875 8.32478V17.1105C0.71875 17.5846 1.08583 17.9676 1.54018 17.9676H5.85268V21.5033C5.85268 21.6212 5.94509 21.7176 6.05804 21.7176H16.942C17.0549 21.7176 17.1473 21.6212 17.1473 21.5033V17.9676H21.4598C21.9142 17.9676 22.2813 17.5846 22.2813 17.1105V8.32478C22.2813 7.14085 21.3623 6.18192 20.2277 6.18192ZM7.59822 2.11049H15.4018V6.18192H7.59822V2.11049ZM15.4018 19.8962H7.59822V12.5033H15.4018V19.8962ZM20.5357 16.1462H17.1473V10.6819H5.85268V16.1462H2.46429V8.32478C2.46429 8.14799 2.6029 8.00335 2.77232 8.00335H20.2277C20.3971 8.00335 20.5357 8.14799 20.5357 8.32478V16.1462Z"
      fill="white"
    />
  </svg>
);

export default printer;
