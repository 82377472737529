const Terminate = () => (
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.147 5.01339L11.3435 0.209821C11.2095 0.0758929 11.0287 0 10.839 0H1.35686C0.961775 0 0.642578 0.319196 0.642578 0.714286V19.2857C0.642578 19.6808 0.961775 20 1.35686 20H15.6426C16.0377 20 16.3569 19.6808 16.3569 19.2857V5.52009C16.3569 5.33036 16.281 5.14732 16.147 5.01339ZM14.7095 5.84821H10.5087V1.64732L14.7095 5.84821ZM14.7497 18.3929H2.24972V1.60714H8.99079V6.42857C8.99079 6.67721 9.08957 6.91567 9.26538 7.09148C9.4412 7.2673 9.67965 7.36607 9.92829 7.36607H14.7497V18.3929ZM8.5466 11.5201L7.16713 9.23438C7.11802 9.15402 7.03097 9.10491 6.93722 9.10491H6.08008C6.02874 9.10491 5.97963 9.1183 5.93722 9.14732C5.81222 9.22545 5.77428 9.39062 5.85463 9.51786L7.69169 12.4286L5.83008 15.3929C5.80479 15.4335 5.79081 15.4802 5.78961 15.5281C5.7884 15.5759 5.80001 15.6232 5.82322 15.6651C5.84644 15.707 5.88042 15.7419 5.92165 15.7663C5.96289 15.7906 6.00988 15.8035 6.05776 15.8036H6.82785C6.9216 15.8036 7.00642 15.7545 7.05552 15.6763L8.45508 13.4107L9.8457 15.6741C9.89481 15.7545 9.98186 15.8013 10.0734 15.8013H10.9104C10.9618 15.8013 11.0109 15.7857 11.0555 15.7589C11.1805 15.6786 11.2162 15.5134 11.1359 15.3884L9.26088 12.4777L11.1649 9.52009C11.1907 9.47955 11.2051 9.43285 11.2067 9.38484C11.2083 9.33683 11.197 9.28927 11.174 9.24711C11.151 9.20495 11.1171 9.16972 11.0758 9.14511C11.0346 9.12049 10.9875 9.10738 10.9395 9.10714H10.1426C10.0488 9.10714 9.96177 9.15625 9.91267 9.23661L8.5466 11.5201Z"
      fill="white"
    />
  </svg>
);

export default Terminate;
