import { FC } from 'react';
import * as S from './styles';

interface ItemProps {
  key: string;
  label: string;
  children: any;
  disabled?: boolean;
}
interface TabsProps {
  defaultActiveKey?: String;
  items: Array<ItemProps>;
  onChange?: Function;
}

const Tabs: FC<TabsProps> = ({ defaultActiveKey = '1', onChange, ...others }) => {
  const { items } = others;
  return (
    <S.Tab defaultActiveKey="1" onChange={onChange} type="card">
      {items.map(item => {
        return (
          <S.ContentTab key={`Tab-${item.key}`} tab={item.label} disabled={item?.disabled}>
            {item.children}
          </S.ContentTab>
        );
      })}
    </S.Tab>
  );
};
export default Tabs;
