import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Pagination as AntPagination } from './antd';

export const Pagination = styled(AntPagination)`
  ${({ theme }) => {
    return css`
      display: flex;
      column-gap: 4px;
      padding-top: 30px;
      width: 100%;
      li {
        &.ant-pagination-item-active {
          border-color: ${theme.primaryColor};
          a {
            color: ${theme.primaryColor};
          }
        }
        &.ant-pagination-total-text {
          color: ${theme.gray7};
          font-family: ${theme.fontFamily};
          font-size: ${theme.fontSizeP};
          margin-right: auto;
        }
        &.ant-pagination-item {
          &:hover {
            border-color: ${theme.primaryColor};
            a {
              color: ${theme.primaryColor};
            }
          }
          &:focus-visible {
            border-color: ${theme.primaryColor};
            a {
              color: ${theme.primaryColor};
            }
          }
        }

        &.ant-pagination-options {
          .ant-pagination-options-size-changer {
            .ant-select-dropdown {
              .ant-select-item-option-selected {
                background-color: ${theme.secudaryColorHover};
              }
            }
            &:hover {
              .ant-select-selector {
                border-color: ${theme.primaryColor};
                a {
                  color: ${theme.primaryColor};
                }
              }
            }
          }
        }
        &.ant-pagination-next,
        &.ant-pagination-prev {
          &:not(.ant-pagination-disabled) {
            &:focus-visible {
              .ant-pagination-item-link {
                border-color: ${theme.primaryColor};
                color: ${theme.primaryColor};
              }
            }
            .ant-pagination-item-link {
              &:hover {
                border-color: ${theme.primaryColor};
                svg {
                  path {
                    fill: ${theme.primaryColor};
                  }
                }
              }
            }
          }
        }
        &.ant-pagination-options {
          & > .ant-select-focused {
            outline: 0;
            border-color: ${theme.primaryColor};
            color: ${theme.primaryColor};
            box-shadow: 0 0 0;
          }
        }
        &.ant-pagination-jump-next-custom-icon,
        &.ant-pagination-jump-prev-custom-icon {
          &:hover,
          &:focus,
          &:focus-visible{
            svg {
              path {
                fill:  ${theme.primaryColor};
              }
            }
          }
        }
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
          .ant-select-selector {
            border-color: ${theme.primaryColor};
            color: ${theme.primaryColor};
            box-shadow: 0 0 0 2px rgba(${theme.primaryColorRGB} / 20%);
          }
        }
      }
    `;
  }}
`;


 
