import { FC, ReactNode } from 'react';
import { Header, Content, Footer, Sider } from './antd';
import * as S from './styles';

type ContainerProps = {
  children?: ReactNode;
  type?: string;
  style?: any;
}

const Container: FC<ContainerProps> = ({
  type = null,
  children,
  ...others
}) => {
  switch (type) {
    case 'header':
      return <Header {...others}>{children}</Header>;
    case 'content':
      return <Content {...others}>{children}</Content>;
    case 'footer':
      return <Footer {...others}>{children}</Footer>;
    case 'sider':
      return <Sider {...others}>{children}</Sider>;
    default:
      return <S.Layout {...others}>{children}</S.Layout>;
  }
};

export default Container;
