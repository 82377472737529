import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Form as AntdForm } from './antd';

export const FormItem = styled(AntdForm.Item)`
  ${({ theme }) => {
    return css`
      .ant-form-item-label {
        padding-bottom: 0;
        line-height: 21px;
        font-size: ${theme.fontSizeLabel};
        font-weight: 400;
        label {
          color: ${theme.gray8};
          font-size: ${theme.fontSizeLabel};
        }
      }
    `;
  }}
`;

export const FormList = styled(AntdForm.List)`
  .ant-form-item-label {
    padding-bottom: 0;
  }
`;

export const Form = styled(AntdForm)``;
