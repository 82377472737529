const emailSimple  = () => (
  <svg
    width="20"
    height="20"
    viewBox="15 20 35 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
   
    <path
      d="M34.7019 35.3023C33.8606 35.9333 32.854 36.2488 31.8624 36.2488C30.8559 36.2488 29.8493 35.9333 29.023 35.3023L17.5 26.6638V39.9746C17.5 41.2967 18.5817 42.3784 19.9038 42.3784H43.8211C45.1432 42.3784 46.2249 41.2967 46.2249 39.9746V26.6488L34.7019 35.3023Z"
    />
    <path
      d="M30.27 33.6197C31.2014 34.3258 32.5085 34.3258 33.4399 33.6197L46.2399 24.0347V23.4038C46.2399 22.0817 45.1582 21 43.8362 21H19.9038C18.5817 21 17.5 22.0817 17.5 23.4038V24.0347L30.27 33.6197Z"
    />
  </svg>
);

export default emailSimple  ;
