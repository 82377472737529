const Pencil = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.82352 14.3551C3.86816 14.3551 3.91281 14.3506 3.95745 14.3439L7.71191 13.6854C7.75656 13.6765 7.79897 13.6564 7.83022 13.6229L17.2923 4.16085C17.313 4.1402 17.3294 4.11567 17.3406 4.08867C17.3518 4.06167 17.3575 4.03272 17.3575 4.00349C17.3575 3.97425 17.3518 3.94531 17.3406 3.9183C17.3294 3.8913 17.313 3.86677 17.2923 3.84612L13.5825 0.134068C13.54 0.0916574 13.4842 0.0693359 13.424 0.0693359C13.3637 0.0693359 13.3079 0.0916574 13.2655 0.134068L3.80343 9.59612C3.76995 9.6296 3.74986 9.66978 3.74093 9.71443L3.08245 13.4689C3.06074 13.5885 3.06849 13.7115 3.10505 13.8274C3.14162 13.9433 3.20587 14.0486 3.29227 14.1341C3.43959 14.2769 3.62486 14.3551 3.82352 14.3551ZM5.32799 10.4622L13.424 2.36844L15.0601 4.0046L6.96415 12.0984L4.97977 12.4488L5.32799 10.4622ZM17.7141 16.2301H1.28557C0.890485 16.2301 0.571289 16.5492 0.571289 16.9443V17.7479C0.571289 17.8461 0.651646 17.9265 0.74986 17.9265H18.2499C18.3481 17.9265 18.4284 17.8461 18.4284 17.7479V16.9443C18.4284 16.5492 18.1092 16.2301 17.7141 16.2301Z"
      fill="#389E0D"
    />
  </svg>
);

export default Pencil;
